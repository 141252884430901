import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  OnDestroy,
  ElementRef,
  signal,
  computed,
  input,
  inject,
  ChangeDetectionStrategy,
  model,
} from '@angular/core';
import {
  AssetItem,
  AssetType,
  HtmlAsset,
  ImageAsset,
  Maybe,
  PlaylistType,
  ScheduleDays,
  VideoAsset,
} from '@designage/gql';
import {
  ActivityStatusColor,
  ActivityStatus,
  ResizeCropMethods,
  TransitionEffects,
  ResizeCropMethodURIs,
} from '@desquare/enums';
import {
  IActivityStatus,
  IScheduledDayIndicator,
  IZoneResolution,
} from '@desquare/interfaces';
import moment from 'moment';
import { NgClass, NgStyle, NgTemplateOutlet, SlicePipe } from '@angular/common';
import {
  AssetItemIsHtml,
  AssetItemIsIFrame,
  AssetItemIsImage,
  AssetItemIsInteractiveTargetVideo,
  AssetItemIsVideo,
  getAssetItemStatus,
  getBlobURL,
  getDateValue,
  getOptimizedUrl,
  scheduleDaysFromGql,
} from '@desquare/utils';
import {
  NgbAccordionModule,
  NgbDropdownModule,
  NgbModal,
  NgbTooltip,
} from '@ng-bootstrap/ng-bootstrap';
import { DeleteFromPlaylistDialogComponent } from '../delete-from-playlist-dialog/delete-from-playlist-dialog.component';
import {
  PlaylistEditorService,
  AspectResizeCropService,
  PlaylistViewService,
  TransitionService,
} from '@desquare/services';
import { SubSink } from 'subsink';
import {
  trapLetters as _trapLetters,
  getActivityStatusColor,
} from '@desquare/utils';
import { cloneDeep, clone } from 'lodash';
import { ThumbnailPreviewDialogComponent } from '../thumbnail-preview-dialog/thumbnail-preview-dialog.component';
import { TranslatePipe } from '@ngx-translate/core';
import { DurationPipe } from '@desquare/components/common/src/pipe/duration/duration.pipe';
import { FormsModule } from '@angular/forms';
import { DateProxyPipe } from '@desquare/components/common/src/pipe/pipe/date-proxy.pipe';
import { DatepickerComponent } from '@desquare/components/common/src/datepicker/datepicker.component';
import { TimepickerComponent } from '@desquare/components/common/src/timepicker/timepicker.component';
import { SafePipe } from '@desquare/components/common/src/pipe/safe/safe.pipe';
import { ContentRowSimpleDetailsComponent } from './content-row-simple-details/content-row-simple-details.component';
import { PlaylistPreviewStore } from '@desquare/stores';

type AssetItemContent = AssetItem & { webpUrl: string };

const inputDateFormat = 'YYYY-MM-DD';

@Component({
  standalone: true,
  imports: [
    NgClass,
    NgStyle,
    NgTemplateOutlet,
    FormsModule,
    NgbAccordionModule,
    NgbTooltip,
    TranslatePipe,

    DurationPipe,
    NgbDropdownModule,
    DatepickerComponent,
    TimepickerComponent,
    SlicePipe,
    SafePipe,
    ContentRowSimpleDetailsComponent,
  ],
  selector: 'app-content-row',
  templateUrl: './content-row.component.html',
  styleUrls: ['./content-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DateProxyPipe],
})
export class ContentRowComponent implements OnInit, OnDestroy {
  /** assetItem is the MEDIA */
  assetItem = model.required<AssetItem>();

  private subs = new SubSink();
  playlistEditorService = inject(PlaylistEditorService);
  assetActivityStatus = input.required<IActivityStatus>();
  playlistPreviewStore = inject(PlaylistPreviewStore);

  isInPreview = computed(() => {
    return (
      this.playlistPreviewStore.currentContent()?.id === this.assetItem().id
    );
  });

  showPopSettings = computed(() => {
    return this.popLicensed() && (this.isImage || this.isVideo);
  });

  popId = computed(() => {
    const item = this.assetItem();
    if (item.type === AssetType.Image) {
      return (item as ImageAsset).popTrackId;
    }
    if (item.type === AssetType.Video) {
      return (item as VideoAsset).popTrackId;
    }
    return '';
  });
  popLicensed = model<boolean>(false);

  readOnly = model<boolean>(false);
  /** this indicates OPEN/CLOSED status */
  isSelected = model<boolean>(false);
  isRowPinned = model<boolean>(false);

  assetVideoItem = computed(() => this.assetItem() as VideoAsset);
  assetImageItem = computed(() => this.assetItem() as ImageAsset);
  assetHtmlItem = computed(() => this.assetItem() as HtmlAsset);

  /** this is the SEQUENCE ID / DAY PART */
  @Input() assetId!: string;

  @Input() simpleUiActive!: boolean;

  @Output() rowClick = new EventEmitter<AssetItem>();
  @Output() editContent = new EventEmitter<{
    assetItem: AssetItem;
    editActions: boolean;
  }>();
  @Output() updateContentItem = new EventEmitter<AssetItem>();
  @Output() replaceContent = new EventEmitter<AssetItem>();
  @Output() deleteAssetItem = new EventEmitter<AssetItem>();
  @Output() duplicateContent = new EventEmitter<AssetItem>();

  @ViewChild('nameInput') nameInput!: ElementRef;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  timeDurationMoment: any;
  minimumTime = moment.unix(1).utc();
  resolutionUnitLabel = 'px';
  style = {};
  _campaignStart!: Maybe<moment.Moment>;
  _campaignEnd!: Maybe<moment.Moment>;
  scheduleDays = signal<IScheduledDayIndicator[]>([]);
  validDurationFormat = true;
  validCampaignStartFormat = true;
  validCampaignEndFormat = true;
  validDurationValue = true;
  isEditingName!: boolean;
  isStartDateChecked = signal<boolean>(false);
  isEndDateChecked = signal<boolean>(false);
  isResizeCropChecked!: boolean;
  campaignStartInput = signal<Maybe<string>>(null);
  campaignEndInput = signal<Maybe<string>>(null);
  timeDurationInput!: string;
  specialCharacters = ['(', ')', ' ', '-', '#', ':'];
  transitionEffect!: string;
  transitionDuration!: number;
  contentDuration!: number;
  showTransition!: boolean;
  showResizeCrop!: Boolean;
  transitionEffects!: string[];
  startTimeInputValue!: string;
  showEffectDurationError = false;
  resizeCrop!: string;
  resizeCropMethods!: string[];
  resizeCropMethod!: string;
  disableResizeCrop = false;
  disabled = false;
  popEnabled = false;

  readonly defaultScheduleDays: ScheduleDays = {
    monday: true,
    tuesday: true,
    wednesday: true,
    thursday: true,
    friday: true,
    saturday: true,
    sunday: true,
    __typename: 'ScheduleDays',
  };

  defaultActivityStatus: IActivityStatus = {
    status: ActivityStatus.INACTIVE,
    style: {
      'background-color': ActivityStatusColor.INACTIVE,
    },
  };

  simulateDateTime = input.required<Maybe<moment.Moment>>();

  activityStatus = computed(() => {
    const activityStatus = getAssetItemStatus(
      this.assetItem(),
      this.scheduleDays(),
      this.assetActivityStatus(),
      this.simulateDateTime()?.toDate(),
    );

    return {
      status: activityStatus,
      style: {
        'background-color': getActivityStatusColor(activityStatus),
      },
    };
  });
  // get activityStatus(): IActivityStatus {
  //   const activityStatus = getAssetItemStatus(
  //     this.assetItem,
  //     this.scheduleDays,
  //     this.assetActivityStatus,
  //     this.simulateDateTime()?.toDate()
  //   );

  //   return {
  //     status: activityStatus,
  //     style: {
  //       'background-color': getActivityStatusColor(activityStatus),
  //     },
  //   };
  // }

  assetType = AssetType;

  thumbnailUri!: Maybe<string>;
  previewUri!: Maybe<string>;
  htmlContent!: Maybe<string>;
  contents: AssetItemContent[] = [];

  htmlUrl!: Maybe<string>;
  isDurationPickerOpen = false;
  trapLetters = _trapLetters;

  private getDateInstance(input: Maybe<moment.Moment | string>): Maybe<Date> {
    try {
      if (!input) {
        return null;
      } else {
        return getDateValue(input);
      }
    } catch {
      return null;
    }
  }

  get campaignStartDate() {
    return this.getDateInstance(this.assetItem().campaignStart);
  }

  get campaignEndDate() {
    return this.getDateInstance(this.assetItem().campaignEnd);
  }

  get isImage() {
    // original (deprecated)
    // assetItem.type === assetType.Image
    return AssetItemIsImage(this.assetItem());
  }

  get isVideo() {
    // assetItem.type === assetType.Video
    return AssetItemIsVideo(this.assetItem());
  }

  get isHtml() {
    // assetItem.type === assetType.Html
    return AssetItemIsHtml(this.assetItem());
  }

  get isIFrame() {
    return AssetItemIsIFrame(this.assetItem());
  }

  get isInteractive() {
    return this.playlistType === PlaylistType.Interactive;
  }

  get isInteractionTargetMedia() {
    return AssetItemIsInteractiveTargetVideo(this.assetItem());
  }

  get playlistType() {
    return this.playlistEditorService.playlistType;
  }

  constructor(
    private datePipe: DateProxyPipe,
    private modalService: NgbModal,
    private transitionService: TransitionService,
    private arcService: AspectResizeCropService,
    private playlistViewService: PlaylistViewService,
  ) {
    // effect(() => {
    //   console.log('assetItem', this.assetItem());
    // });
  }

  ngOnInit() {
    this.initValues();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  get title() {
    return this.assetItem().name;
  }

  //TODO: WHY THE FUCK is this called COLLAPSED???
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  set timeDurationString(value: any) {
    this.assetItem().duration = value ?? 0;
  }

  get timeDurationString() {
    return this.assetItem().duration ?? 0;
  }

  /** returns a descriptive label of campaign start/end */
  get dateDuration() {
    const startDate =
      this.assetItem().campaignStart && this.validCampaignStartFormat
        ? this.transformDate(this.assetItem().campaignStart ?? '')
        : '';
    const endDate =
      this.assetItem().campaignEnd && this.validCampaignEndFormat
        ? this.transformDate(this.assetItem().campaignEnd ?? '')
        : '';

    return startDate && endDate
      ? startDate + ' >> ' + endDate
      : startDate
        ? startDate + ' >> '
        : endDate
          ? '>> ' + endDate
          : '';
  }

  get campaignStart() {
    return this.campaignStartDate ? moment(this.campaignStartDate) : null;
  }

  get campaignEnd() {
    return this.campaignEndDate ? moment(this.campaignEndDate) : null;
  }

  private getUri(resolution?: IZoneResolution): Maybe<string> {
    switch (this.assetItem().type) {
      case AssetType.Video: {
        const videoThumbnail = `${this.uri?.substring(
          0,
          this.uri.lastIndexOf('.'),
        )}.webp`;
        return getOptimizedUrl(AssetType.Image, videoThumbnail, resolution);
      }
      case AssetType.Image: {
        const assetItem = this.assetItem() as ImageAsset;
        const resizeCropMethod =
          assetItem.__typename === 'ImageAsset'
            ? assetItem.resizeCropMethod?.uri
            : ResizeCropMethodURIs.PAD_URI;
        return assetItem.uri
          ? getOptimizedUrl(
              AssetType.Image,
              assetItem.uri,
              resolution,
              resizeCropMethod,
            )
          : '';
      }

      default:
        return null;
    }
  }

  private getHtmlUrl() {
    return this.htmlContent ? getBlobURL(this.htmlContent, 'text/html') : '';
  }
  get uri() {
    const assetItem = this.assetItem();
    return 'uri' in assetItem && assetItem.uri ? assetItem.uri : '';
  }

  get thumbnailUrl() {
    switch (this.assetItem().type) {
      case AssetType.Image:
        return this.getUri({
          resolution: '',
          width: 0,
          height: 200,
          aspect: 0,
          orientation: '',
          default: false,
        });
        break;
      case AssetType.Video:
        return this.getUri({
          resolution: '',
          width: 0,
          height: 200,
          aspect: 0,
          orientation: '',
          default: false,
        });
        break;
      default:
        return '';
    }
  }

  private initValues() {
    this.contentDuration = (this.assetItem().duration || 0) / 1000;
    this.htmlContent =
      'htmlContent' in this.assetItem
        ? (this.assetItem() as HtmlAsset).htmlContent
        : null;
    this.transitionEffects = this.transitionService.getTransitions();
    this.resizeCropMethods = this.arcService.getResizeCropMethods();

    switch (this.assetItem().type) {
      case AssetType.Image:
        // Pass only the height for now.
        const imageAsset = this.assetItem() as ImageAsset;
        this.showResizeCrop = true;
        this.showTransition = true;
        if (imageAsset.__typename === 'ImageAsset') {
          this.transitionEffect =
            imageAsset.transitionEffect?.name?.toUpperCase() ||
            TransitionEffects.CUT;
          this.transitionDuration = imageAsset.transitionEffect?.duration || 0;
          this.transitionDuration = this.transitionDuration / 1000; // stored in ms
          this.resizeCropMethod =
            imageAsset.resizeCropMethod?.name?.toUpperCase() ||
            ResizeCropMethods.NONE_BEAUTY;
          this.disableResizeCrop = imageAsset.disableOptimization ?? false;
          this.popEnabled = imageAsset.popEnabled ?? false;
        }
        break;
      case AssetType.Video:
        // Pass only the height for now.
        const videoAsset = this.assetItem() as VideoAsset;
        this.showResizeCrop = true;
        this.showTransition = false;
        if (videoAsset.__typename === 'VideoAsset') {
          this.resizeCropMethod =
            videoAsset.resizeCropMethod?.name?.toUpperCase() ||
            ResizeCropMethods.NONE_BEAUTY;
          this.disableResizeCrop = videoAsset.disableOptimization ?? false;
          this.popEnabled = videoAsset.popEnabled ?? false;
        }
        break;
      case AssetType.Html:
        this.htmlUrl = this.getHtmlUrl();
        this.showResizeCrop = false;
        this.showTransition = false;
        break;
      case AssetType.Iframe:
        this.showResizeCrop = false;
        this.showTransition = false;
        break;
      default:
        throw new Error(
          `Unidentified asset item type ${this.assetItem().type}`,
        );
    }
    this.setResizeCropCheckbox();

    if (!this.assetItem().days) {
      this.assetItem().days = cloneDeep(this.defaultScheduleDays);
    }

    this.isStartDateChecked.set(!!this.assetItem().campaignStart);
    this.isEndDateChecked.set(!!this.assetItem().campaignEnd);

    const assetDays = this.assetItem().days as ScheduleDays;
    this.scheduleDays.set(this.extractDays(assetDays));

    this.timeDurationMoment = moment.unix(this.timeDurationString / 1000).utc();

    this.timeDurationInput = moment(this.timeDurationMoment).format('HH:mm:ss');
    this.campaignStartInput.set(
      this.campaignStart ? this.campaignStart?.format('YYYY-MM-DD') : '',
    );
    this.campaignEndInput.set(
      this.campaignEnd ? this.campaignEnd?.format('YYYY-MM-DD') : '',
    );
  }

  private transformDate(dateString: string) {
    return this.datePipe.transform(dateString, 'shortDate');
  }

  private getDayIndicatorStyle(isSet: boolean): Record<string, string> {
    return {
      'background-color': isSet ? '#178863' : '',
      border: isSet ? '1px solid #178863' : '1px solid #f1556c',
    };
  }

  private extractDays(days: ScheduleDays) {
    return scheduleDaysFromGql(days, this.getDayIndicatorStyle);
  }

  onRowPin() {
    if (this.assetItem().id) {
      this.isRowPinned.update((value) => !value);
      this.playlistViewService.setAssetContentPinned(
        this.assetId,
        this.assetItem().id!,
        this.isRowPinned(),
      );
      this.playlistEditorService.closePickers.emit();
    }
  }

  onRowClick() {
    if (this.assetItem().id) {
      this.isSelected.update((value) => !value);
      this.playlistViewService.setContentOpenStatus(
        this.assetId,
        this.assetItem().id!,
        this.isSelected(),
      );
      this.playlistEditorService.closePickers.emit();
    }
  }

  setCampaignStart(reset?: boolean) {
    if (reset) {
      this.campaignStartInput.set(
        this.isStartDateChecked()
          ? moment(new Date()).format(inputDateFormat)
          : '',
      );

      if (!this.isStartDateChecked) {
        this.assetItem().days = cloneDeep(this.defaultScheduleDays);
        this.scheduleDays.set(this.extractDays(this.assetItem().days!));
      }
    }
    console.log('setCampaignStart', this.campaignStartInput());
    if (this.campaignStartInput()) {
      this.validCampaignStartFormat = moment(
        this.campaignStartInput(),
        inputDateFormat,
        true,
      ).isValid();
      const date = this.validCampaignStartFormat
        ? new Date(this.campaignStartInput() as string)
            .toISOString()
            .split('.')[0]
        : null;

      console.log('setCampaignStart date', date);

      this.assetItem().campaignStart = date || null; // ?.toISOString() || '';
    } else {
      this.campaignStartInput.set(null);
      this.validCampaignStartFormat = true;
      this.assetItem().campaignStart = null;
    }

    this.campaignStartInput.set(
      this.campaignStart ? this.campaignStart?.format(inputDateFormat) : '',
    );

    this.emitSelectedAssets();
  }
  onCampaignStartClose(date?: Maybe<string>) {
    if (date) this.campaignStartInput.set(date);

    console.log('onCampaignStartClose', date);

    this.setCampaignStart();
    this.playlistEditorService.setSequenceValidity();
  }

  setCampaignEnd(reset?: boolean) {
    if (reset) {
      this.campaignEndInput.set(
        this.isEndDateChecked()
          ? moment(new Date()).format(inputDateFormat)
          : '',
      );
      if (!this.isEndDateChecked) {
        this.assetItem().days = cloneDeep(this.defaultScheduleDays);
        this.scheduleDays.set(this.extractDays(this.assetItem().days!));
      }
    }
    if (this.campaignEndInput()) {
      this.validCampaignEndFormat = moment(
        this.campaignEndInput(),
        inputDateFormat,
        true,
      ).isValid();
      const date = this.validCampaignEndFormat
        ? `${
            new Date(this.campaignEndInput() as string)
              .toISOString()
              .split('T')[0]
          }T23:59:59`
        : null;

      console.log('setCampaignEnd date', date);

      this.assetItem().campaignEnd = date;
    } else {
      this.campaignEndInput.set(null);
      this.validCampaignEndFormat = true;
      this.assetItem().campaignEnd = null;
    }

    this.campaignEndInput.set(
      this.campaignEnd ? this.campaignEnd?.format(inputDateFormat) : '',
    );

    this.emitSelectedAssets();
  }
  onCampaignEndClose(date?: Maybe<string>) {
    if (date) this.campaignEndInput.set(date);

    this.setCampaignEnd();

    this.playlistEditorService.setSequenceValidity();
  }

  switchDayStatus(index: number) {
    const scheduledDay = this.scheduleDays()[index];

    scheduledDay.isSet = !this.scheduleDays()[index].isSet;
    scheduledDay.style = this.getDayIndicatorStyle(scheduledDay.isSet);

    if (this.assetItem().days) {
      this.assetItem().days![scheduledDay.key] = scheduledDay.isSet;
    }

    this.emitSelectedAssets();
  }

  onBlur() {
    this.emitSelectedAssets();
  }

  onDelete() {
    const modal = this.modalService.open(DeleteFromPlaylistDialogComponent);
    modal.componentInstance.messagePrompt = 'DELETE_CONTENT_PROMPT';
    modal.result
      .then(() => {
        this.deleteAssetItem.emit(this.assetItem());
      })
      .catch(() => {});
  }

  onEditContent(editActions: boolean = false) {
    if (this.assetItem())
      this.editContent.emit({
        assetItem: this.assetItem(),
        editActions: editActions,
      });
  }
  onDisableSwitch() {
    this.assetItem().disabled = !this.assetItem().disabled;
    this.emitSelectedAssets();
  }

  onDisableOptimizedUrl(assetType: AssetType, value: boolean) {
    if (assetType === AssetType.Video) {
      const assetItem = this.assetItem() as VideoAsset;
      assetItem.disableOptimization = value;
      this.disableResizeCrop = value;
    }
    if (assetType === AssetType.Image) {
      const assetItem = this.assetItem() as ImageAsset;
      assetItem.disableOptimization = value;
      this.disableResizeCrop = value;
    }
    this.emitSelectedAssets();
  }

  onPopSwitch(e: Event) {
    const bool = (e.target as HTMLInputElement).checked;
    if (this.assetItem().__typename === 'ImageAsset') {
      const assetItem = this.assetItem() as ImageAsset;
      assetItem.popEnabled = bool;
      this.popEnabled = bool;
    }
    if (this.assetItem().__typename === 'VideoAsset') {
      const assetItem = this.assetItem() as VideoAsset;
      assetItem.popEnabled = bool;
      this.popEnabled = bool;
    }
    this.emitSelectedAssets();
  }

  setPopTrackId(e: FocusEvent) {
    const id = (e.target as HTMLInputElement).value;
    const item = this.isVideo
      ? this.assetVideoItem
      : this.isImage
        ? this.assetImageItem
        : undefined;
    if (item) {
      item().popTrackId = id;
      this.emitSelectedAssets();
    }
  }
  onEditInteractiveActions() {
    if (this.assetItem())
      this.editContent.emit({
        assetItem: this.assetItem(),
        editActions: true,
      });
  }

  onReplaceContent() {
    this.replaceContent.emit(this.assetItem());
  }

  onDuplicate() {
    this.duplicateContent.emit(this.assetItem());
  }

  onDurationInputClose() {
    // if (this.isDurationPickerOpen) {
    this.isDurationPickerOpen = false;
    this.validDurationFormat = moment(
      this.timeDurationInput,
      'HH:mm:ss',
      true,
    ).isValid();

    if (this.validDurationFormat) {
      this.validDurationValue = this.timeDurationInput !== '00:00:00';
      const rawTime = moment(this.timeDurationInput, 'HH:mm:ss');
      this.timeDurationMoment = moment.isMoment(rawTime) ? rawTime : null;
      this.timeDurationString =
        this.timeDurationMoment?.valueOf() -
        this.timeDurationMoment.startOf('day').valueOf();
    } else {
      this.timeDurationString = null;
    }
    console.log('timeDurationString', this.timeDurationString);

    this.emitSelectedAssets();
    this.playlistEditorService.setSequenceValidity();
    this.contentDuration = (this.assetItem().duration || 0) / 1000;
    // }
  }

  onDurationInputOpen() {
    // prevent (close) from triggering when clicking anywhere in the page although picker is not open
    this.isDurationPickerOpen = true;
  }

  emitSelectedAssets() {
    this.playlistEditorService.sequenceTouch.emit(true);
    this.playlistEditorService.emitEditingSequencesChange();
  }

  resetDpCampaignEndDateBlocks() {
    const campaignEndClone = clone(this.campaignEndInput);

    this.campaignEndInput.set(null);

    setTimeout(() => {
      this.campaignEndInput =
        this.campaignStartInput &&
        campaignEndClone &&
        this.campaignStartInput > campaignEndClone
          ? this.campaignStartInput
          : campaignEndClone;
    });
  }

  resetDpCampaignStartDateBlocks() {
    const campaignStartClone = clone(this.campaignStartInput);

    this.campaignEndInput.set(null);

    setTimeout(() => {
      this.campaignStartInput = campaignStartClone;
    });
  }

  openPreviewDialog() {
    const modalRef = this.modalService.open(ThumbnailPreviewDialogComponent, {
      windowClass: 'custom-centered-modal',
      size: 'lg',
    });

    const componentInput =
      modalRef.componentInstance as ThumbnailPreviewDialogComponent;
    componentInput.assetItem.set(this.assetItem());

    if (this.assetItem().__typename === 'VideoAsset') {
      this.playlistEditorService.previewPlayToggleTriggered.emit(false);
    }
  }

  toggleEdit(cancel: boolean = false) {
    if (cancel) {
      this.isEditingName = false;
    } else {
      this.assetItem.update((state) => {
        state = cloneDeep(state);
        state.name = this.assetItem().name;
        return state;
      });
      this.isEditingName = false;
    }
  }

  editName() {
    this.isEditingName = true;
    setTimeout(() => {
      this.nameInput.nativeElement.select();
    });
  }

  onCheckBoxStartDate() {
    this.isStartDateChecked.set(!this.isStartDateChecked());
    console.log('oncheck');

    this.setCampaignStart(true);

    this.playlistEditorService.setSequenceValidity();
  }

  onCheckBoxEndDate() {
    this.isEndDateChecked.set(!this.isEndDateChecked());
    this.setCampaignEnd(true);

    this.playlistEditorService.setSequenceValidity();
  }

  onCheckBoxResizeCrop() {
    this.isResizeCropChecked = !this.isResizeCropChecked;
    if (!this.isResizeCropChecked) {
      this.setResizeCrop(ResizeCropMethods.NONE_BEAUTY);
    } else {
      this.setResizeCrop(ResizeCropMethods.PAD_BEAUTY);
    }
  }

  get notAllDays() {
    return this.scheduleDays().some((day) => day.isSet === false);
  }

  setSelectedTransition(selectedTransition: string) {
    if (this.assetItem && this.assetItem().__typename === 'ImageAsset') {
      const assetItem = this.assetItem() as ImageAsset;
      this.transitionDuration =
        selectedTransition !== TransitionEffects.CUT ? 1 : 0;
      this.transitionEffect = selectedTransition;
      assetItem.transitionEffect = {
        name: this.transitionEffect,
        duration: this.transitionDuration * 1000,
        __typename: 'TransitionEffect',
      };
      this.emitSelectedAssets();
    }
  }

  setTransitionDuration(e: Event) {
    const duration = parseInt((e.target as HTMLInputElement).value);
    if (duration > this.contentDuration) {
      return;
    }
    if (this.assetItem && this.assetItem().__typename === 'ImageAsset') {
      const assetItem = this.assetItem() as ImageAsset;
      this.transitionDuration = duration;
      assetItem.transitionEffect = {
        name: this.transitionEffect,
        duration: this.transitionDuration * 1000,
        __typename: 'TransitionEffect',
      };
      this.emitSelectedAssets();
    }
  }
  setResizeCropCheckbox() {
    if (this.resizeCropMethod === undefined) {
      this.resizeCropMethod = ResizeCropMethods.NONE_BEAUTY;
    }
    if (this.resizeCropMethod === ResizeCropMethods.NONE_BEAUTY) {
      this.isResizeCropChecked = false;
    } else {
      this.isResizeCropChecked = true;
    }
  }
  setResizeCrop(selectedResizeCrop: string) {
    // TODO: revisit later when we have typescript v4.9
    // the type assertions below can be improved by changing them
    // to use the 'satisfies' operator instead

    if (this.assetItem && this.assetItem().type === AssetType.Image) {
      const imageAssetItem = this.assetItem() as ImageAsset;

      this.resizeCropMethod = selectedResizeCrop;
      this.setResizeCropCheckbox();
      const methodUri = this.arcService.getResizeCropUri(
        AssetType.Image,
        this.resizeCropMethod,
      );
      imageAssetItem.resizeCropMethod = {
        name: this.resizeCropMethod,
        uri: methodUri,
        __typename: 'ResizeCropMethod',
      };
      this.emitSelectedAssets();
      this.playlistEditorService.setSequenceValidity();
    }
    if (this.assetItem && this.assetItem().type === AssetType.Video) {
      const videoAssetItem = this.assetItem() as VideoAsset;

      this.resizeCropMethod = selectedResizeCrop;
      this.setResizeCropCheckbox();
      const methodUri = this.arcService.getResizeCropUri(
        AssetType.Video,
        this.resizeCropMethod,
      );
      videoAssetItem.resizeCropMethod = {
        name: this.resizeCropMethod,
        uri: methodUri,
        __typename: 'ResizeCropMethod',
      };
      this.emitSelectedAssets();
      this.playlistEditorService.setSequenceValidity();
    }
  }
}
