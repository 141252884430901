import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  signal,
  computed,
  input,
  inject,
  ChangeDetectionStrategy,
  model,
} from '@angular/core';
import {
  AssetItem,
  AssetType,
  HtmlAsset,
  ImageAsset,
  Maybe,
  PlaylistType,
  ScheduleDays,
  VideoAsset,
} from '@designage/gql';
import {
  ActivityStatusColor,
  ActivityStatus,
  ResizeCropMethods,
  TransitionEffects,
  ResizeCropMethodURIs,
  ConfirmationResponse,
} from '@desquare/enums';
import { IActivityStatus, IZoneResolution } from '@desquare/interfaces';
import moment from 'moment';
import { NgClass, NgStyle, NgTemplateOutlet, SlicePipe } from '@angular/common';
import {
  AssetItemIsHtml,
  AssetItemIsIFrame,
  AssetItemIsImage,
  AssetItemIsInteractiveTargetVideo,
  AssetItemIsVideo,
  AssetItemToImageOrVideo,
  getAssetItemStatus,
  getBlobURL,
  getDateValue,
  getOptimizedUrl,
  scheduleDaysFromGql,
} from '@desquare/utils';
import {
  NgbAccordionModule,
  NgbDropdownModule,
  NgbModal,
  NgbTooltip,
} from '@ng-bootstrap/ng-bootstrap';
import {
  PlaylistEditorService,
  AspectResizeCropService,
  TransitionService,
  PopupService,
} from '@desquare/services';
import {
  trapLetters as _trapLetters,
  getActivityStatusColor,
} from '@desquare/utils';
import { cloneDeep } from 'lodash';
import { ThumbnailPreviewDialogComponent } from '@designage/app/playlist-sequence/thumbnail-preview-dialog/thumbnail-preview-dialog.component';
import { TranslatePipe } from '@ngx-translate/core';
import { DurationPipe } from '@desquare/components/common/src/pipe/duration/duration.pipe';
import { FormsModule } from '@angular/forms';
import { DateProxyPipe } from '@desquare/components/common/src/pipe/pipe/date-proxy.pipe';
import { DatepickerComponent } from '@desquare/components/common/src/datepicker/datepicker.component';
import { TimepickerComponent } from '@desquare/components/common/src/timepicker/timepicker.component';
import { SafePipe } from '@desquare/components/common/src/pipe/safe/safe.pipe';
import { PlaylistPreviewStore, PlaylistStore } from '@desquare/stores';
import { DesValidationMessageComponent } from '@desquare/ui-components/des-validation-message.component';
type AssetItemContent = AssetItem & { webpUrl: string };

const inputDateTimeFormat = 'YYYY-MM-DDTHH:mm:ss';
@Component({
  standalone: true,
  imports: [
    NgClass,
    NgStyle,
    NgTemplateOutlet,
    FormsModule,
    NgbAccordionModule,
    NgbTooltip,
    TranslatePipe,
    DurationPipe,
    NgbDropdownModule,
    DatepickerComponent,
    TimepickerComponent,
    DesValidationMessageComponent,
    SlicePipe,
    SafePipe,
  ],
  selector: 'app-content-item',
  templateUrl: './content-item.component.html',
  styleUrls: ['./content-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DateProxyPipe],
})
export class ContentItemComponent implements OnInit {
  playlistStore = inject(PlaylistStore);
  playlistPreviewStore = inject(PlaylistPreviewStore);
  popupService = inject(PopupService);
  datePipe = inject(DateProxyPipe);
  modalService = inject(NgbModal);
  transitionService = inject(TransitionService);
  arcService = inject(AspectResizeCropService);

  /** input contentItem is the MEDIA */
  contentItem = input.required<AssetItem>();

  /** proof of play */
  popLicensed = model<boolean>(false);
  readOnly = model<boolean>(false);
  channelView = input<boolean>(false);
  @Input() editingAssetIdx = -1;
  @Input() editingItemIdx = -1;

  popEnabled = computed(() => {
    const item = this.contentItem();
    if (item.type === AssetType.Image) {
      return (item as ImageAsset).popEnabled;
    }
    if (item.type === AssetType.Video) {
      return (item as VideoAsset).popEnabled;
    }
    return false;
  });

  isInPreview = computed(() => {
    return (
      this.playlistPreviewStore.currentContent()?.id === this.contentItem().id
    );
  });

  validFields = this.playlistStore.isValid;

  simulateDateTime = input.required<Maybe<moment.Moment>>();

  playlistEditorService = inject(PlaylistEditorService);
  assetActivityStatus = input.required<IActivityStatus>();

  assetVideoItem = computed(() => this.contentItem() as VideoAsset);
  assetImageItem = computed(() => this.contentItem() as ImageAsset);
  assetHtmlItem = computed(() => this.contentItem() as HtmlAsset);
  isAssetVideoOrImage = computed(() => {
    return (
      this.contentItem().__typename === 'VideoAsset' ||
      this.contentItem().__typename === 'ImageAsset'
    );
  });

  popId = computed(() => {
    const item = this.contentItem();
    if (item.type === AssetType.Image) {
      return (item as ImageAsset).popTrackId;
    }
    if (item.type === AssetType.Video) {
      return (item as VideoAsset).popTrackId;
    }
    return '';
  });

  /** this is the SEQUENCE ID / DAY PART */
  @Input() assetId!: string;

  @Input() simpleUiActive!: boolean;

  @Output() editContent = new EventEmitter<{
    contentItem: AssetItem;
    itemIdx: number;
    editActions: boolean;
  }>();
  @Output() updateContentItem = new EventEmitter<AssetItem>();
  @Output() replaceContent = new EventEmitter<number>();

  @ViewChild('nameInput') nameInput!: ElementRef;

  campaignStartValue = computed(() => {
    return this.contentItem().campaignStart?.split('T')[0] || '';
  });

  campaignEndValue = computed(() => {
    return this.contentItem().campaignEnd?.split('T')[0] || '';
  });

  optimizedUrlDisabled = computed((): boolean => {
    if (this.isAssetVideoOrImage()) {
      return (
        AssetItemToImageOrVideo(this.contentItem())?.disableOptimization ??
        false
      );
    }
    return false;
  });

  get canEdit() {
    return (
      this.editingAssetIdx >= 0 && this.editingItemIdx >= 0 && !this.readOnly()
    );
  }
  async onDelete() {
    if (this.canEdit) {
      const response = await this.popupService.confirm(
        'DELETE_CONFIRMATION',
        this.contentItem().name,
      );

      if (response === ConfirmationResponse.Yes) {
        this.playlistStore.deleteContentItem(
          this.editingAssetIdx,
          this.editingItemIdx,
        );
      }
    }
  }

  showPopSettings = computed(() => {
    return this.popLicensed() && (this.isImage || this.isVideo);
  });

  // TODO: call popup from service, call store from this component, remove events
  onEditContent(editActions: boolean = false) {
    if (this.contentItem())
      this.editContent.emit({
        contentItem: this.contentItem(),
        itemIdx: this.editingItemIdx,
        editActions: editActions,
      });
  }
  onDisableSwitch() {
    this.playlistStore.setContentItemDisabled(
      this.editingAssetIdx,
      this.editingItemIdx,
      !this.contentItem().disabled,
    );
  }

  onDisableOptimizedUrl(disabledOpt: boolean) {
    if (this.isResizeCropChecked) {
      this.isResizeCropChecked = false;
      this.setResizeCrop(ResizeCropMethods.NONE_BEAUTY);
    }
    this.playlistStore.setContentItemDisabledOptimization(
      this.editingAssetIdx,
      this.editingItemIdx,
      disabledOpt,
    );
  }

  // TODO: call popup from service, call store from this component, remove events
  onEditInteractiveActions() {
    if (this.contentItem())
      this.editContent.emit({
        contentItem: this.contentItem(),
        itemIdx: this.editingItemIdx,
        editActions: true,
      });
  }

  // TODO: call popup from service, call store from this component, remove events
  onReplaceContent() {
    this.replaceContent.emit(this.editingItemIdx);
  }

  onDuplicate() {
    this.playlistStore.duplicateContentItem(
      this.editingAssetIdx,
      this.editingItemIdx,
    );
  }

  onDurationInputClose() {
    // TODO: simplify this shit, there's no user free input, we only need transformation between hh:mm:ss to milliseconds
    this.isDurationPickerOpen = false;
    this.validDurationFormat = moment(
      this.timeDurationInput,
      'HH:mm:ss',
      true,
    ).isValid();

    if (this.validDurationFormat) {
      this.validDurationValue = this.timeDurationInput !== '00:00:00';
      const rawTime = moment(this.timeDurationInput, 'HH:mm:ss');
      this.timeDurationMoment = moment.isMoment(rawTime) ? rawTime : null;
      const milliseconds =
        this.timeDurationMoment?.valueOf() -
        this.timeDurationMoment.startOf('day').valueOf();

      // TODO: only thing really needed
      this.playlistStore.setContentItemDuration(
        this.editingAssetIdx,
        this.editingItemIdx,
        milliseconds,
      );

      this.durationMs = milliseconds;
    } else {
      this.durationMs = null;
    }

    this.durationSecs = (this.contentItem().duration || 0) / 1000;
  }

  onDurationInputOpen() {
    // prevent (close) from triggering when clicking anywhere in the page although picker is not open
    this.isDurationPickerOpen = true;
  }

  /********************* */
  /** old code, to be reduced/removed? */

  // eslint-disable-next-line @typescript-eslint/no-explicit-any

  minimumTime = moment.unix(1).utc();
  resolutionUnitLabel = 'px';
  style = {};
  _campaignStart!: Maybe<moment.Moment>;
  _campaignEnd!: Maybe<moment.Moment>;
  scheduleDays = computed(() => {
    return this.extractDays(
      this.contentItem().days || cloneDeep(this.defaultScheduleDays),
    );
  });
  validDurationFormat = true;
  validCampaignStartFormat = true;
  validCampaignEndFormat = true;
  validDurationValue = true;
  isEditingName!: boolean;
  isStartDateChecked = computed(() => !!this.contentItem().campaignStart);
  isEndDateChecked = computed(() => !!this.contentItem().campaignEnd);
  isResizeCropChecked!: boolean;

  timeDurationMoment: any;
  /** HH:mm:ss string */
  timeDurationInput!: string;
  durationSecs!: number;

  specialCharacters = ['(', ')', ' ', '-', '#', ':'];
  transitionEffect = signal<string>('');
  transitionDurationSecs = signal<number>(0);
  showTransition = computed(() => {
    return this.contentItem().__typename === 'ImageAsset';
  });

  transitionEffects!: string[];
  startTimeInputValue!: string;
  showEffectDurationError = false;
  resizeCrop!: string;
  resizeCropMethods!: string[];
  resizeCropMethod = signal<string>('');
  disableResizeCrop = computed(() => {
    const imgOrVideo = AssetItemToImageOrVideo(this.contentItem());
    return imgOrVideo?.disableOptimization || false;
  });

  disabled = false;

  readonly defaultScheduleDays: ScheduleDays = {
    monday: true,
    tuesday: true,
    wednesday: true,
    thursday: true,
    friday: true,
    saturday: true,
    sunday: true,
    __typename: 'ScheduleDays',
  };

  defaultActivityStatus: IActivityStatus = {
    status: ActivityStatus.INACTIVE,
    style: {
      'background-color': ActivityStatusColor.INACTIVE,
    },
  };

  activityStatus = computed(() => {
    const activityStatus = getAssetItemStatus(
      this.contentItem(),
      this.scheduleDays(),
      this.assetActivityStatus(),
      this.simulateDateTime()?.toDate(),
    );

    return {
      status: activityStatus,
      style: {
        'background-color': getActivityStatusColor(activityStatus),
      },
      class:
        activityStatus === ActivityStatus.ACTIVE
          ? 'bg-playlist-status-active'
          : activityStatus === ActivityStatus.INACTIVE
            ? 'bg-playlist-status-inactive'
            : activityStatus === ActivityStatus.WAITING
              ? 'bg-playlist-status-waiting'
              : null,
    };
  });

  assetType = AssetType;

  thumbnailUri!: Maybe<string>;
  previewUri!: Maybe<string>;
  htmlContent!: Maybe<string>;
  contents: AssetItemContent[] = [];

  htmlUrl!: Maybe<string>;
  isDurationPickerOpen = false;
  trapLetters = _trapLetters;

  private getDateInstance(input: Maybe<moment.Moment | string>): Maybe<Date> {
    try {
      if (!input) {
        return null;
      } else {
        return getDateValue(input);
      }
    } catch {
      return null;
    }
  }

  // TODO: all following getters should become computed signals

  get campaignStartDate() {
    return this.getDateInstance(this.contentItem().campaignStart);
  }

  get campaignEndDate() {
    return this.getDateInstance(this.contentItem().campaignEnd);
  }

  get isImage() {
    // original (deprecated)
    // contentItem.type === assetType.Image
    return AssetItemIsImage(this.contentItem());
  }

  get isVideo() {
    // contentItem.type === assetType.Video
    return AssetItemIsVideo(this.contentItem());
  }

  get isHtml() {
    // contentItem.type === assetType.Html
    return AssetItemIsHtml(this.contentItem());
  }

  get isIFrame() {
    return AssetItemIsIFrame(this.contentItem());
  }

  get isInteractive() {
    return this.playlistType === PlaylistType.Interactive;
  }

  get isInteractionTargetMedia() {
    return AssetItemIsInteractiveTargetVideo(this.contentItem());
  }

  get playlistType() {
    return this.playlistStore.playlist.type();
  }

  ngOnInit() {
    this.initValues();
  }

  get title() {
    return this.contentItem().name;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  set durationMs(value: any) {
    this.contentItem().duration = value ?? 0;
  }

  get durationMs() {
    return this.contentItem().duration ?? 0;
  }

  /** returns a descriptive label of campaign start/end */
  get dateDuration() {
    const startDate =
      this.contentItem().campaignStart && this.validCampaignStartFormat
        ? this.transformDate(this.contentItem().campaignStart ?? '')
        : '';
    const endDate =
      this.contentItem().campaignEnd && this.validCampaignEndFormat
        ? this.transformDate(this.contentItem().campaignEnd ?? '')
        : '';

    return startDate && endDate
      ? startDate + ' >> ' + endDate
      : startDate
        ? startDate + ' >> '
        : endDate
          ? '>> ' + endDate
          : '';
  }

  get campaignStart() {
    return this.campaignStartDate ? moment(this.campaignStartDate) : null;
  }

  get campaignEnd() {
    return this.campaignEndDate ? moment(this.campaignEndDate) : null;
  }

  private getUri(resolution?: IZoneResolution): Maybe<string> {
    switch (this.contentItem().type) {
      case AssetType.Video: {
        const videoThumbnail = `${this.uri?.substring(
          0,
          this.uri.lastIndexOf('.'),
        )}.webp`;
        return getOptimizedUrl(AssetType.Image, videoThumbnail, resolution);
      }
      case AssetType.Image: {
        const contentItem = this.contentItem() as ImageAsset;
        const resizeCropMethod =
          contentItem.__typename === 'ImageAsset'
            ? contentItem.resizeCropMethod?.uri
            : ResizeCropMethodURIs.PAD_URI;
        return contentItem.uri
          ? getOptimizedUrl(
              AssetType.Image,
              contentItem.uri,
              resolution,
              resizeCropMethod,
            )
          : '';
      }

      default:
        return null;
    }
  }

  private getHtmlUrl() {
    return this.htmlContent ? getBlobURL(this.htmlContent, 'text/html') : '';
  }
  get uri() {
    const contentItem = this.contentItem();
    return 'uri' in contentItem && contentItem.uri ? contentItem.uri : '';
  }

  get thumbnailUrl() {
    switch (this.contentItem().type) {
      case AssetType.Image:
        return this.getUri({
          resolution: '',
          width: 0,
          height: 200,
          aspect: 0,
          orientation: '',
          default: false,
        });
        break;
      case AssetType.Video:
        return this.getUri({
          resolution: '',
          width: 0,
          height: 200,
          aspect: 0,
          orientation: '',
          default: false,
        });
        break;
      default:
        return '';
    }
  }

  private initValues() {
    this.durationMs = this.contentItem().duration || 0;
    this.durationSecs = this.durationMs / 1000;
    this.htmlContent =
      'htmlContent' in this.contentItem
        ? (this.contentItem() as HtmlAsset).htmlContent
        : null;
    this.transitionEffects = this.transitionService.getTransitions();
    this.resizeCropMethods = this.arcService.getResizeCropMethods();

    switch (this.contentItem().type) {
      case AssetType.Image:
        // Pass only the height for now.
        const imageAsset = this.contentItem() as ImageAsset;
        if (imageAsset.__typename === 'ImageAsset') {
          this.transitionEffect.set(
            imageAsset.transitionEffect?.name?.toUpperCase() ||
              TransitionEffects.CUT,
          );
          this.transitionDurationSecs.set(
            imageAsset.transitionEffect?.duration || 0,
          );
          this.transitionDurationSecs.set(
            this.transitionDurationSecs() / 1000, // stored in ms
          );
          this.resizeCropMethod.set(
            imageAsset.resizeCropMethod?.name?.toUpperCase() ||
              ResizeCropMethods.NONE_BEAUTY,
          );
        }
        break;
      case AssetType.Video:
        // Pass only the height for now.
        const videoAsset = this.contentItem() as VideoAsset;
        if (videoAsset.__typename === 'VideoAsset') {
          this.resizeCropMethod.set(
            videoAsset.resizeCropMethod?.name?.toUpperCase() ||
              ResizeCropMethods.NONE_BEAUTY,
          );
        }
        break;
      case AssetType.Html:
        this.htmlUrl = this.getHtmlUrl();
        break;
      case AssetType.Iframe:
        break;
      default:
        throw new Error(
          `Unidentified asset item type ${this.contentItem().type}`,
        );
    }
    this.setResizeCropCheckbox();

    if (!this.contentItem().days) {
      this.contentItem().days = cloneDeep(this.defaultScheduleDays);
    }

    this.timeDurationMoment = moment.unix(this.durationMs / 1000).utc();

    this.timeDurationInput = moment(this.timeDurationMoment).format('HH:mm:ss');
  }

  private transformDate(dateString: string) {
    return this.datePipe.transform(dateString, 'shortDate');
  }

  private getDayIndicatorStyle(isSet: boolean): Record<string, string> {
    return {
      'background-color': isSet ? '#178863' : '',
      border: isSet ? '1px solid #178863' : '1px solid #f1556c',
    };
  }

  private extractDays(days: ScheduleDays) {
    return scheduleDaysFromGql(days, this.getDayIndicatorStyle);
  }

  onRowPin() {
    if (!this.canEdit) return;

    this.playlistStore.setContentItemPin(
      this.editingAssetIdx,
      this.editingItemIdx,
    );
    this.playlistEditorService.closePickers.emit();
  }

  onPopSwitch() {
    this.playlistStore.setItemPop(
      this.editingAssetIdx,
      this.editingItemIdx,
      'toggle',
    );
  }

  setPopTrackId(e: FocusEvent) {
    const popTrackId = (e.target as HTMLInputElement).value;
    this.playlistStore.setItemPopTrackId(
      this.editingAssetIdx,
      this.editingItemIdx,
      popTrackId,
    );
  }

  onRowClick() {
    if (!this.canEdit) return;
    this.playlistStore.setContentItemOpen(
      this.editingAssetIdx,
      this.editingItemIdx,
    );
    // this.playlistEditorService.closePickers.emit();
  }

  onCampaignStartClose(date?: Maybe<string>) {
    if (!this.canEdit) return;

    if (date) this.setCampaignStart(date);
  }

  onCheckBoxStartDate() {
    if (!this.canEdit) return;
    if (this.contentItem().campaignStart) {
      return this.playlistStore.setContentItemCampaignDate(
        this.editingAssetIdx,
        this.editingItemIdx,
        'campaignStart',
        null,
      );
    }
    // Use moment to check if campaignEnd is earlier than current datetime
    const campaignEndMoment = this.contentItem().campaignEnd
      ? moment(this.contentItem().campaignEnd).endOf('day')
      : null;
    if (campaignEndMoment && campaignEndMoment.isBefore(moment())) {
      return this.setCampaignStart(campaignEndMoment.toISOString());
    }
    this.setCampaignStart(new Date().toISOString());
  }

  setCampaignStart(dateInput?: string) {
    if (dateInput) {
      const date = moment(dateInput).startOf('day').format(inputDateTimeFormat);

      this.playlistStore.setContentItemCampaignDate(
        this.editingAssetIdx,
        this.editingItemIdx,
        'campaignStart',
        date,
      );
    } else {
      this.validCampaignStartFormat = true;
      this.playlistStore.setContentItemCampaignDate(
        this.editingAssetIdx,
        this.editingItemIdx,
        'campaignStart',
        null,
      );
    }
  }

  onCheckBoxEndDate() {
    if (!this.canEdit) return;
    if (this.contentItem().campaignEnd) {
      return this.playlistStore.setContentItemCampaignDate(
        this.editingAssetIdx,
        this.editingItemIdx,
        'campaignEnd',
        null,
      );
    }
    const campaignStartMoment = this.contentItem().campaignStart
      ? moment(this.contentItem().campaignStart).startOf('day')
      : null;
    if (campaignStartMoment && campaignStartMoment.isAfter(moment())) {
      return this.setCampaignEnd(campaignStartMoment.toISOString());
    }
    this.setCampaignEnd(new Date().toISOString());
  }

  onCampaignEndClose(date?: Maybe<string>) {
    if (!this.canEdit) return;

    if (date) this.setCampaignEnd(date);
  }

  setCampaignEnd(dateInput?: string) {
    if (dateInput) {
      const date = moment(dateInput).endOf('day').format(inputDateTimeFormat);

      this.playlistStore.setContentItemCampaignDate(
        this.editingAssetIdx,
        this.editingItemIdx,
        'campaignEnd',
        date,
      );
    } else {
      this.validCampaignEndFormat = true;
      this.playlistStore.setContentItemCampaignDate(
        this.editingAssetIdx,
        this.editingItemIdx,
        'campaignEnd',
        null,
      );
    }
  }

  switchDayStatus(index: number) {
    if (!this.canEdit) return;

    const scheduledDay = this.scheduleDays()[index];

    scheduledDay.isSet = !scheduledDay.isSet;
    scheduledDay.style = this.getDayIndicatorStyle(scheduledDay.isSet);

    const days = this.contentItem().days || cloneDeep(this.defaultScheduleDays);
    if (days) {
      days[scheduledDay.key] = scheduledDay.isSet;
      this.playlistStore.setContentItemDays(
        this.editingAssetIdx,
        this.editingItemIdx,
        days,
      );
    }
  }

  openPreviewDialog() {
    const modalRef = this.modalService.open(ThumbnailPreviewDialogComponent, {
      windowClass: 'custom-centered-modal',
      size: 'lg',
    });

    const componentInput =
      modalRef.componentInstance as ThumbnailPreviewDialogComponent;
    componentInput.assetItem.set(this.contentItem());

    if (this.contentItem().__typename === 'VideoAsset') {
      this.playlistEditorService.previewPlayToggleTriggered.emit(false);
    }
  }

  toggleEdit(cancel: boolean = false) {
    if (!this.canEdit) return;

    if (cancel) {
      this.isEditingName = false;
    } else {
      this.playlistStore.setContentItemName(
        this.editingAssetIdx,
        this.editingItemIdx,
        this.nameInput.nativeElement.value,
      );
      this.isEditingName = false;
    }
  }

  editName() {
    if (!this.canEdit) return;

    this.isEditingName = true;
    setTimeout(() => {
      this.nameInput.nativeElement.select();
    });
  }

  onCheckBoxResizeCrop() {
    if (!this.canEdit) return;

    this.isResizeCropChecked = !this.isResizeCropChecked;
    if (!this.isResizeCropChecked) {
      this.setResizeCrop(ResizeCropMethods.NONE_BEAUTY);
    } else {
      this.setResizeCrop(ResizeCropMethods.PAD_BEAUTY);
    }
  }

  get notAllDays() {
    return this.scheduleDays().some((day) => day.isSet === false);
  }

  setSelectedTransition(selectedTransition: string) {
    if (this.contentItem && this.contentItem().__typename === 'ImageAsset') {
      const contentItem = this.contentItem() as ImageAsset;
      this.transitionDurationSecs.set(
        selectedTransition !== TransitionEffects.CUT ? 1 : 0,
      );
      this.transitionEffect.set(selectedTransition);
      contentItem.transitionEffect = {
        name: this.transitionEffect(),
        duration: this.transitionDurationSecs() * 1000,
        __typename: 'TransitionEffect',
      };
      this.playlistStore.setContentItemTransitionEffect(
        this.editingAssetIdx,
        this.editingItemIdx,
        contentItem.transitionEffect,
      );
    }
  }

  onTransitionDurationChange(event: Event) {
    if (!this.canEdit) return;

    const newTransitionDurationSecs = +(event.target as HTMLInputElement).value;

    if (newTransitionDurationSecs > this.durationSecs) {
      this.transitionDurationSecs.set(this.durationSecs);
    }
    if (this.contentItem && this.contentItem().__typename === 'ImageAsset') {
      const contentItem = this.contentItem() as ImageAsset;
      this.transitionDurationSecs.set(newTransitionDurationSecs);
      contentItem.transitionEffect = {
        name: this.transitionEffect(),
        duration: this.transitionDurationSecs() * 1000,
        __typename: 'TransitionEffect',
      };
      this.playlistStore.setContentItemTransitionEffect(
        this.editingAssetIdx,
        this.editingItemIdx,
        contentItem.transitionEffect,
      );
    }
  }

  setResizeCropCheckbox() {
    if (this.resizeCropMethod() === undefined) {
      this.resizeCropMethod.set(ResizeCropMethods.NONE_BEAUTY);
    }
    if (this.resizeCropMethod() === ResizeCropMethods.NONE_BEAUTY) {
      this.isResizeCropChecked = false;
    } else {
      this.isResizeCropChecked = true;
    }
  }
  setResizeCrop(selectedResizeCrop: string) {
    // TODO: revisit later when we have typescript v4.9
    // the type assertions below can be improved by changing them
    // to use the 'satisfies' operator instead

    if (this.contentItem() && this.contentItem().type === AssetType.Image) {
      const imageContentItem = this.contentItem() as ImageAsset;

      this.resizeCropMethod.set(selectedResizeCrop);
      this.setResizeCropCheckbox();
      const methodUri = this.arcService.getResizeCropUri(
        AssetType.Image,
        this.resizeCropMethod(),
      );
      imageContentItem.resizeCropMethod = {
        name: this.resizeCropMethod(),
        uri: methodUri,
        __typename: 'ResizeCropMethod',
      };
      this.playlistStore.setContentItemResizeCrop(
        this.editingAssetIdx,
        this.editingItemIdx,
        imageContentItem.resizeCropMethod,
      );
    }
    if (this.contentItem && this.contentItem().type === AssetType.Video) {
      const videoContentItem = this.contentItem() as VideoAsset;

      this.resizeCropMethod.set(selectedResizeCrop);
      this.setResizeCropCheckbox();
      const methodUri = this.arcService.getResizeCropUri(
        AssetType.Video,
        this.resizeCropMethod(),
      );
      videoContentItem.resizeCropMethod = {
        name: this.resizeCropMethod(),
        uri: methodUri,
        __typename: 'ResizeCropMethod',
      };
      this.playlistStore.setContentItemResizeCrop(
        this.editingAssetIdx,
        this.editingItemIdx,
        videoContentItem.resizeCropMethod,
      );
    }
  }
}
