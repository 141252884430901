import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  IInteractiveLayoutModalResponse,
  ISmilRegion,
} from '@desquare/interfaces';
import { LayoutEditorService } from '@desquare/services';
import { NgbActiveModal, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { SubSink } from 'subsink';
import { TranslatePipe } from '@ngx-translate/core';
import { LayoutFormComponent } from '../layout-form/layout-form.component';
import { LayoutType } from '@desquare/enums';

@Component({
  standalone: true,
  imports: [NgbTooltip, LayoutFormComponent, TranslatePipe],
  selector: 'app-interactive-layout-manage-dialog',
  templateUrl: './interactive-layout-manage-dialog.component.html',
  styleUrls: ['./interactive-layout-manage-dialog.component.scss'],
  providers: [LayoutEditorService],
})
export class InteractiveLayoutManageDialogComponent
  implements OnInit, OnDestroy
{
  @Input() source?: string;
  @Input() interactiveRegions?: ISmilRegion[];
  @Input() backgroundUrl?: string;
  @Input() width = 1280;
  @Input() height = 720;

  subs = new SubSink();

  layoutManageForm = new FormGroup({});
  LayoutType = LayoutType;

  constructor(
    public editorModal: NgbActiveModal,
    private layoutEditorService: LayoutEditorService,
    private formBuilder: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.setFormValues();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  /**
   * the forms mentioned in this function will be initialized
   * in their respective components
   */
  setFormValues() {
    interface LayoutManageForm {
      detailSettingsForm?: FormGroup;
    }

    const DETAIL_SETTINGS_FORM_GROUP_NAME = 'detailSettingsForm';
    const detailSettingsFormGroup = (
      this.layoutManageForm.controls as LayoutManageForm
    )[DETAIL_SETTINGS_FORM_GROUP_NAME] as FormGroup;

    const formValue = {
      name: '',
      description: '',
      width: this.width,
      height: this.height,
      color: '#000000',
    };

    if (detailSettingsFormGroup) {
      detailSettingsFormGroup.patchValue(formValue);
    } else {
      this.layoutManageForm.addControl(
        DETAIL_SETTINGS_FORM_GROUP_NAME,
        this.formBuilder.group(formValue),
      );
    }

    /*

      const SOURCE_FORM_GROUP_NAME = 'layoutSourceForm';
    const layoutSourceFormGroup =
      this.layoutManageForm.controls[SOURCE_FORM_GROUP_NAME];


    if (layoutSourceFormGroup) {
      
      const source: ILayoutEditorSource = this.layout
        .source as ILayoutEditorSource;
      layoutSourceFormGroup.patchValue({
        sceneUrl: source.sceneUrl,
        cloudinaryPublicId: source.cloudinaryPublicId,
        regionBlocks: source.regionBlocks,
      });

    }*/
  }

  async saveAndClose() {
    const source = await this.layoutEditorService.saveSceneToString();
    const { width, height } = this.layoutEditorService.getCanvas();
    const interactiveRegions = this.layoutEditorService.getRegionBlocks();
    const response: IInteractiveLayoutModalResponse = {
      source,
      interactiveRegions,
      width,
      height,
    };
    this.editorModal.close(response);
  }
  close() {
    this.editorModal.close();
  }
}
