import { Component, Input } from '@angular/core';
import { AssetItem, Maybe } from '@designage/gql';
import { FormDialogComponent } from '@desquare/components/common/src/modals/form-dialog.component';
import { AssetContentFormComponent } from '../asset-content-form/asset-content-form.component';

@Component({
  standalone: true,
  imports: [FormDialogComponent, AssetContentFormComponent],
  selector: 'app-edit-asset-content-dialog',
  template: `<app-form-dialog
    closeButtonText="OK"
    dismissButtonText="CANCEL"
    [valid]="isFormValid"
    [pristine]="isFormPristine"
    headerText="REPLACE_CONTENT"
    [values]="value"
  >
    <app-asset-content-form
      [resource]="content"
      [multiSelect]="multiSelect"
      (valid)="isFormValid = $event"
      (pristine)="isFormPristine = $event"
      (value)="value = $event"
    />
  </app-form-dialog> `,
})
export class EditAssetContentDialogComponent {
  @Input() content!: AssetItem;

  multiSelect = false;

  isFormValid = false;
  isFormPristine = true;
  loaderMessage!: string;
  loading = true;
  value!: Maybe<AssetItem>;
}
