@if (loading() || deleting()) {
  <div class="d-flex justify-content-center align-items-center h-100">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">{{
        loading() ? ('LOADING' | translate) : ('DELETING' | translate)
      }}</span>
    </div>
  </div>
} @else {
  @if (uiMode$ | async; as uiMode) {
    <form
      class="d-flex flex-column h-100 overflow-hidden"
      (keydown.enter)="$event.preventDefault()"
    >
      <!-- MARK: Playlist header -->
      <div class="row pt-3 pb-2 border-bottom">
        <!-- [class.playlist-header-expanded]="playlistSchedular()?.editingRules()" -->
        <!-- MARK: Playlist name -->
        <div
          class="mb-2 d-flex flex-column flex-md-row align-items-top justify-content-between sliding-panel-header"
        >
          <div
            class="icon-appear-on-hover position-relative w-100 overflow-hidden text-truncate"
            style="min-width: 10rem"
            [class.validation-border]="!validFields().tests['name'].valid"
          >
            @if (isEditingName() || !playlist.name()) {
              <input
                #playlistNameInput
                (click)="$event.stopPropagation()"
                class="form-control form-control-dark name-input"
                (blur)="updatePlaylistName()"
                (keydown.enter)="updatePlaylistName()"
                placeholder="{{ 'PLAYLIST_NAME' | translate }}"
              />
            } @else {
              <label
                class="d-inline text-truncate pointer-text"
                (click)="editPlaylistNameInput()"
                [class.text-muted]="!playlist.name()"
              >
                {{ playlist.name() || '...' | translate }}
              </label>
              <i
                (click)="editPlaylistNameInput(); $event.stopPropagation()"
                class="px-2 playlist-name-edit-icon ri-pencil-fill text-primary"
              ></i>
            }
            @if (!validFields().tests['name'].valid) {
              <des-validation-message
                [message]="validFields().tests['name'].errors[0]"
                [color]="'danger'"
              />
            }
          </div>
          <!-- MARK: Save and publish buttons -->
          <div
            class="d-flex align-items-center justify-content-between justify-content-md-end w-100 pe-5"
          >
            <div
              class="w-auto d-flex flex-nowrap align-items-center pe-0 pe-md-3"
            >
              <!-- Status label -->
              <div class="me-2 d-none d-lg-block">
                {{ 'STATUS' | translate }}:
              </div>
              <span
                class="mx-1 badge"
                [class.bg-warning]="
                  playlist().status === playlistStatus.Draft &&
                  !changeDetected()
                "
                [class.bg-warning]="
                  playlist().status === playlistStatus.ReadyToPublish &&
                  !changeDetected()
                "
                [class.bg-primary]="
                  playlist().status === playlistStatus.Published &&
                  !changeDetected()
                "
                [class.bg-danger]="
                  playlist().status === playlistStatus.Deprecated &&
                  !changeDetected()
                "
                [class.bg-danger]="changeDetected()"
              >
                {{
                  playlist().status && !changeDetected()
                    ? (playlist().status | translate)
                    : ('NOT_SAVED' | translate)
                }}
              </span>
            </div>
            <div class="w-auto d-flex flex-nowrap pe-0 pe-md-4">
              @if (createPlaylist()) {
                <des-button
                  class="mx-1"
                  [color]="'warning'"
                  [outlineButton]="false"
                  [loaderText]="'SAVING'"
                  (clickAction)="saveNew()"
                  [loading]="saving()"
                >
                  {{ 'SAVE' | translate }}
                </des-button>
              } @else {
                <des-button
                  class="mx-1"
                  [color]="'warning'"
                  [disabled]="!changeDetected() || !validFields().valid"
                  [outlineButton]="
                    playlist().status === playlistStatus.ReadyToPublish &&
                    !changeDetected()
                  "
                  [loaderText]="'SAVING'"
                  (clickAction)="save()"
                  [loading]="saving()"
                >
                  {{ 'SAVE' | translate }}
                </des-button>
              }
              <des-button
                class="ms-1"
                [color]="'success'"
                [disabled]="
                  playlist().status != playlistStatus.ReadyToPublish ||
                  changeDetected()
                "
                [outlineButton]="
                  playlist().status != playlistStatus.ReadyToPublish ||
                  changeDetected()
                "
                [loaderText]="'PUBLISHING'"
                (clickAction)="publish()"
                [loading]="publishing()"
              >
                {{ 'PUBLISH' | translate }}
              </des-button>

              <div class="ms-2" ngbDropdown #moreActions="ngbDropdown">
                <button
                  type="button"
                  class="btn btn-secondary mx-2"
                  id="dropdownManual"
                  ngbDropdownAnchor
                  (focus)="moreActions.open()"
                >
                  {{ 'MORE' | translate }}
                </button>
                <div
                  ngbDropdownMenu
                  aria-labelledby="dropdownManual"
                  class="p-2"
                >
                  <button
                    ngbDropdownItem
                    type="button"
                    class="my-2 badge"
                    [class]="
                      (playlistUpdatedExternally() || changeDetected()) &&
                      !this.createPlaylist()
                        ? 'bg-warning text-white'
                        : ''
                    "
                    [disabled]="
                      (!playlistUpdatedExternally() && !changeDetected()) ||
                      this.createPlaylist()
                    "
                    [ngbTooltip]="'REVERT_PLAYLIST_TT' | translate"
                    (click)="openRevertPlaylistDialog()"
                  >
                    {{ 'REVERT_PLAYLIST' | translate }}
                  </button>
                  @if (playlist().status && invokedFrom() != 'channelPage') {
                    <button
                      ngbDropdownItem
                      type="button"
                      class="my-2 badge"
                      (click)="openDeletePlaylistDialog()"
                      [class]="
                        !this.createPlaylist() ? 'bg-danger text-white' : ''
                      "
                      [disabled]="this.createPlaylist()"
                    >
                      {{ 'DELETE' | translate }}
                    </button>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="row col-12 pt-5 pt-md-0 align-items-start pe-0 my-3 my-md-0"
        >
          <!-- MARK: Playlist type -->
          <div class="d-none d-sm-block col-4">
            <div class="grid gap-2 align-items-baseline">
              <div
                class="g-col-12 text-truncate mt-1"
                style="height: 1.8rem"
                ngbDropdown
                container="body"
              >
                <input
                  type="button"
                  class="form-control form-control-dark form-select dropdown mb-2 w-100 text-truncate"
                  [class.bg-exclusive-playlist]="
                    playlist().type === PlaylistType.Exclusive
                  "
                  [class.bg-triggered-playlist]="
                    playlist().type === PlaylistType.ExclusiveTrigger
                  "
                  style="height: 1.6rem; max-width: 20rem"
                  ngbDropdownToggle
                  [ngbTooltip]="
                    'PLAYLIST.PLAYLIST_TYPE_' + playlistType() + '_TT'
                      | translate
                  "
                  value="{{
                    'PLAYLIST.PLAYLIST_TYPE_' + playlistType() | translate
                  }}"
                />
                <div ngbDropdownMenu class="shadowed-box text-white">
                  <span
                    ngbDropdownItem
                    class="dropdown-items"
                    (click)="setPlaylistType(PlaylistType.Scheduled)"
                    >{{
                      'PLAYLIST.PLAYLIST_TYPE_' + PlaylistType.Scheduled
                        | translate
                    }}</span
                  >
                  <span
                    ngbDropdownItem
                    class="dropdown-items"
                    (click)="setPlaylistType(PlaylistType.Exclusive)"
                    >{{
                      'PLAYLIST.PLAYLIST_TYPE_' + PlaylistType.Exclusive
                        | translate
                    }}</span
                  >
                  <!--
                  <span
                    ngbDropdownItem
                    class="dropdown-items"
                    (click)="setPlaylistType(PlaylistType.ExclusiveTrigger)"
                    >{{
                      'PLAYLIST.PLAYLIST_TYPE_' + PlaylistType.ExclusiveTrigger
                        | translate
                    }}</span 
                  >
                --></div>
              </div>
              @if (!createPlaylist()) {
                <div class="g-col-4 text-truncate mt-1">
                  {{ 'LAST_UPDATED' | translate }}:
                </div>
                <div class="g-col-8 text-truncate mt-1">
                  {{
                    (playlist.updatedAt() | date: 'short') ||
                      ('NO_DATA' | translate)
                  }}
                  @if (playlist.user && playlist.user()?.displayName !== '') {
                    {{ 'BY' | translate }}
                    {{ playlist.user()?.displayName }}
                  }
                </div>
              }
            </div>
          </div>

          <!-- Playlist Schedule -->
          @if (!isInteractive()) {
            <!-- MARK: Playlist Don't play before -->
            <playlist-schedular
              #playlistSchedular
              class="h-100 col-12 col-sm-8 d-flex flex-column justify-content-between position-relative"
              [playlist]="playlist()"
              [simulateDateTime]="simulateDateTime()"
            />
          }
        </div>
      </div>
      @if (responsiveUiService.sm()) {
        <ng-container [ngTemplateOutlet]="playlistAsSplit"></ng-container>
      } @else {
        <ng-container
          [ngTemplateOutlet]="playlistSettingsPanels"
        ></ng-container>
      }
      <ng-template #playlistAsSplit>
        <as-split
          [direction]="responsiveUiService.lg() ? 'horizontal' : 'vertical'"
          useTransition="true"
          unit="percent"
        >
          <as-split-area
            [size]="responsiveUiService.lg() ? '60' : '40'"
            minSize="35"
            class="pt-2"
          >
            <ng-container
              [ngTemplateOutlet]="playlistSequenceManager"
            ></ng-container>
          </as-split-area>
          <as-split-area
            [size]="responsiveUiService.lg() ? '40' : '60'"
            minSize="30"
            class="pt-4"
          >
            <ng-container
              [ngTemplateOutlet]="playlistSettingsPanels"
            ></ng-container>
          </as-split-area>
        </as-split>
      </ng-template>
      <ng-template #playlistSequenceManager>
        <div class="h-100 overflow-x-hidden">
          <playlist-assets-list
            [simulateDateTime]="simulateDateTime()"
            [simpleUiActive]="uiMode.simpleUiMode || isInteractive()"
          ></playlist-assets-list>
        </div>
      </ng-template>

      <ng-template #playlistSettingsPanels>
        <div class="d-flex flex-column h-100">
          <ul
            ngbNav
            [activeId]="activeIdSettingsPanel()"
            (activeIdChange)="activeIdSettingsPanel.set($event)"
            #nav="ngbNav"
            class="my-2 my-lg-0 mx-0 mx-md-3 nav nav-pills justify-content-center justify-content-lg-start"
          >
            @if (responsiveUiService.isMobileDevice()) {
              <li [ngbNavItem]="1" class="nav-item">
                <a ngbNavLink>
                  {{ 'PLAYLIST.PLAYLIST' | translate }}
                </a>
                <ng-template ngbNavContent>
                  <ng-container
                    *ngTemplateOutlet="playlistSequenceManager"
                  ></ng-container>
                </ng-template>
              </li>
            }
            @if (!isInteractive()) {
              <li [ngbNavItem]="2" class="nav-item" [destroyOnHide]="false">
                <a ngbNavLink>
                  {{ 'VIEWER' | translate }}
                </a>
                <ng-template ngbNavContent>
                  <ng-container
                    *ngTemplateOutlet="contentPreview"
                  ></ng-container>
                </ng-template>
              </li>
            }
            <li [ngbNavItem]="3" class="nav-item">
              <a ngbNavLink>
                {{ 'CONTENT' | translate }}
              </a>
              <ng-template ngbNavContent>
                <ng-container *ngTemplateOutlet="mediaGallery"></ng-container>
              </ng-template>
            </li>

            @if (
              invokedFrom() != 'channelPage' &&
              currentUserService.canManageChannels
            ) {
              <li [ngbNavItem]="4" class="nav-item">
                <a ngbNavLink>
                  {{ 'channel.CHANNEL_ASSIGN' | translate }}
                </a>
                <ng-template ngbNavContent>
                  <ng-container
                    *ngTemplateOutlet="channelRegionSelection"
                  ></ng-container>
                </ng-template>
              </li>
            }

            @if (!!playlist().status) {
              <li [ngbNavItem]="5" class="nav-item">
                <a ngbNavLink>
                  {{ 'HISTORY' | translate }}
                </a>
                <ng-template ngbNavContent>
                  <ng-container
                    *ngTemplateOutlet="playlistVersion"
                  ></ng-container>
                </ng-template>
              </li>
            }
          </ul>
          <div [ngbNavOutlet]="nav" class="overflow-auto h-100"></div>
        </div>
      </ng-template>
      <ng-template #contentPreview>
        <!-- [playlist]="playlist()" -->
        <app-content-preview
          #preview
          [isBrowserRes]="responsiveUiService.lg() || false"
          [channels]="channels"
          [selectedChannelRegions]="selectedChannels()"
          previewButtonText="PREVIEW_PLAYLIST"
          publishedButtonText="PUBLISHED_PLAYLIST"
          noContentText="NO_ADDED_VIEW"
          [showSimulateSchedule]="true"
          [showAspectRatio]="true"
        ></app-content-preview>
      </ng-template>

      <ng-template #channelRegionSelection>
        <app-channel-region-selection
          [channels]="channels"
          [selectedChannels]="selectedChannels() || []"
          (selectedChannelsChange)="setSelectedChannels($event)"
        />
      </ng-template>

      <ng-template #mediaGallery>
        <app-media-list
          [dropTargetIds]="assetIds()"
          [enableCheckbox]="false"
          [PlaylistMediaLibrary]="true"
          [showCreativeEditor]="false"
          [showHeader]="false"
          [enableSlidePanel]="false"
          [showFolderExplorerText]="false"
          [folderExplorerWidth]="150"
        />
      </ng-template>

      <ng-template #playlistVersion>
        @if (playlist().id) {
          <app-playlist-version
            [playlistId]="playlist().id"
            [(activeChannels)]="activeChannels"
            (revertButtonClick)="revertToVersion($event)"
            (createCopyButtonClick)="createCopy($event)"
          ></app-playlist-version>
        }
      </ng-template>
    </form>
  }
}
