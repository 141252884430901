import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  computed,
  effect,
  inject,
  input,
  signal,
  viewChild,
} from '@angular/core';
import { NgClass } from '@angular/common';
import moment from 'moment';
import { DatepickerComponent } from '@desquare/components/common/src/datepicker/datepicker.component';
import { TimepickerComponent } from '@desquare/components/common/src/timepicker/timepicker.component';
import { playlistScheduleStatus } from '@desquare/utils';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Maybe, Playlist, PlaylistStatus, PlaylistType } from '@designage/gql';
import { PlaylistStore } from '@desquare/stores';
import { ScheduleRulesComponent } from './scheduleRules.component';
import {
  NgbDropdownModule,
  NgbModal,
  NgbPopoverModule,
} from '@ng-bootstrap/ng-bootstrap';
import { parseGeneralScheduleText } from './ruleParser';
import { DateProxyPipe } from '../pipe/pipe/date-proxy.pipe';

@Component({
  selector: 'playlist-schedular',
  standalone: true,
  imports: [
    NgClass,
    DatepickerComponent,
    TimepickerComponent,
    TranslateModule,
    ScheduleRulesComponent,
    NgbPopoverModule,
    NgbDropdownModule,
  ],
  template: `
    <div
      class="schedule-container"
      (mouseover)="mouseOver()"
      (mouseleave)="mouseLeave()"
    >
      <div
        class="card flex-column"
        style="grid-column: 1 / 3;grid-row: 1 / 2; border-top-left-radius: 0;"
      >
        <!-- Main schedule settings -->
        <div class="d-flex">
          <div class="d-flex align-items-center mx-1">
            <label
              class="text-nowrap form-check-label ps-2 fs-6 text-white"
              for="startDate"
              >{{ 'PLAYLIST.PLAYLIST_SCHEDULE.FROM' | translate }}:</label
            >
            <input
              id="startDate"
              type="checkbox"
              class="mx-1 form-check-input"
              [checked]="isStartDateChecked()"
              (change)="onPlaylistScheduleDateCheck('startDate', $event)"
            />
            <designage-datepicker
              [disabled]="!isStartDateChecked()"
              [date]="startDateValue()"
              [maxDate]="endDateValue()"
              (dateChange)="setScheduleDate('startDate', $event)"
            />
            <input
              id="startDate"
              type="checkbox"
              class="mx-1 form-check-input"
              [disabled]="!isStartDateChecked()"
              [checked]="isStartTimeChecked()"
              (change)="onPlaylistScheduleTimeCheck('startDate', $event)"
            />
            <designage-timepicker
              [showSecond]="false"
              [disabled]="!isStartTimeChecked()"
              [time]="startTimeValue()"
              [placeholder]="'HH:MM'"
              (timeChange)="setScheduleTime('startDate', $event)"
            />
          </div>
          <div class="d-flex align-items-center mx-1">
            <label
              class="text-nowrap form-check-label ps-2 fs-6 text-white"
              for="endDate"
              >{{ 'PLAYLIST.PLAYLIST_SCHEDULE.TO' | translate }}:</label
            >
            <div class="d-flex align-items-center mx-1">
              <input
                id="endDate"
                type="checkbox"
                [checked]="isEndDateChecked()"
                class="mx-1 form-check-input"
                (change)="onPlaylistScheduleDateCheck('endDate', $event)"
              />
              <designage-datepicker
                [disabled]="!isEndDateChecked()"
                [date]="endDateValue()"
                [minDate]="startDateValue()"
                (dateChange)="setScheduleDate('endDate', $event)"
              />
              <input
                id="endDate"
                type="checkbox"
                class="mx-1 form-check-input"
                [disabled]="!isEndDateChecked()"
                [checked]="isEndTimeChecked()"
                (change)="onPlaylistScheduleTimeCheck('endDate', $event)"
              />
              <designage-timepicker
                [showSecond]="false"
                [disabled]="!isEndTimeChecked()"
                [placeholder]="'HH:MM'"
                [time]="endTimeValue()"
                (timeChange)="setScheduleTime('endDate', $event)"
              />
            </div>
          </div>
        </div>
        <!-- Schedule text -->
        @if (scheduleAsText()) {
          <div class="ms-2 p-1 fs-6 text-white">
            <span>{{ scheduleAsText() }}</span>
          </div>
        }
      </div>

      <div
        class="schedule-status-container"
        [ngClass]="{
          'outline-playlist-status-active':
            playlistScheduleStatus() === 'ACTIVE',
          'outline-playlist-status-inactive':
            playlistScheduleStatus() === 'INACTIVE',
          'outline-playlist-status-waiting':
            playlistScheduleStatus() === 'WAITING',
        }"
      >
        <div class="schedule-tab">
          <div class="progress-bar">
            {{ 'PLAYLIST.PLAYLIST_SCHEDULE.MAIN_SCHEDULE' | translate }}
          </div>
        </div>
      </div>
      <div
        class="card position-relative mt-3 rules-container"
        [class.h-auto]="editingRules() || hoveringOverSchedule()"
      >
        <div class=" card-body p-2 overflow-hidden">
          <playlist-schedule-rules
            [playlistSchedule]="playlist().schedule!"
            [playlistScheduleStatus]="playlistScheduleStatus()"
            [hoveringOverSchedule]="hoveringOverSchedule()"
            (editingRulesActive)="onEditingRulesChange($event)"
          />
        </div>
        <div class="schedule-tab start-0">
          <div class="progress-bar">
            {{ 'PLAYLIST.PLAYLIST_SCHEDULE.RULES_SCHEDULE' | translate }}
          </div>
        </div>
      </div>
      <div
        class="mt-3 grid gap-2 align-items-top "
        style="grid-column: 1 / 3;grid-row: 2 / 3;"
      >
        <div class="g-col-1"></div>
      </div>
    </div>
  `,
  styleUrls: ['./playlistSchedular.component.scss'],
  providers: [DateProxyPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlaylistSchedularComponent {
  playlist = input.required<Playlist>();
  simulateDateTime = input<Maybe<moment.Moment>>();
  playlistStore = inject(PlaylistStore);
  modal = inject(NgbModal);
  elementRef = inject(ElementRef);
  translate = inject(TranslateService);
  dateTimePipe = inject(DateProxyPipe);

  hoveringOverSchedule = signal(false);

  scheduleRulesComponent = viewChild<ScheduleRulesComponent>('scheduleRules');
  editingRules = signal(false);

  /** enum of statuses */
  playlistStatus = PlaylistStatus;
  PlaylistType = PlaylistType;
  isStartDateChecked = computed(() => {
    return !!this.playlist().schedule?.startDate;
  });

  isEndDateChecked = computed(() => {
    return !!this.playlist().schedule?.endDate;
  });

  isStartTimeChecked = computed(() => {
    const startDate =
      moment(this.playlist().schedule?.startDate)
        .utc(false)
        .format('HH:mm:ss') !=
      moment(this.playlist().schedule?.startDate)
        .utc(false)
        .startOf('day')
        .format('HH:mm:ss');
    return !!startDate;
  });

  isEndTimeChecked = computed(() => {
    const endDate =
      moment(this.playlist().schedule?.endDate).utc(false).format('HH:mm:ss') !=
      moment(this.playlist().schedule?.endDate)
        .utc(false)
        .endOf('day')
        .format('HH:mm:ss');
    return !!endDate;
  });

  startDateValue = computed(() => {
    return this.playlist().schedule?.startDate?.split('T')[0] || '';
  });

  endDateValue = computed(() => {
    return this.playlist().schedule?.endDate?.split('T')[0] || '';
  });
  startTimeValue = computed(() => {
    return this.playlist().schedule?.startDate?.split('T')[1] || '';
  });

  endTimeValue = computed(() => {
    return this.playlist().schedule?.endDate?.split('T')[1] || '';
  });

  playlistScheduleStatus = computed(
    (): 'ACTIVE' | 'INACTIVE' | 'WAITING' | undefined => {
      const date = this.simulateDateTime()
        ? moment(this.simulateDateTime()).utc(true)
        : moment().utc(true);
      const status = playlistScheduleStatus(
        this.playlist().schedule?.startDate,
        this.playlist().schedule?.endDate,
        date,
      );
      return status;
    },
  );

  scheduleType = computed(() => {
    if (this.playlist().schedule?.rules === null) {
      return 'STANDARD';
    } else {
      return 'RULES';
    }
  });
  scheduleAsText = computed(() =>
    parseGeneralScheduleText(
      this.playlist().schedule!,
      this.translate,
      this.dateTimePipe,
    ),
  );

  constructor() {
    effect(() => {
      console.log('schedule ', this.playlist().schedule);
    });
  }

  mouseOver() {
    this.hoveringOverSchedule.set(true);
  }

  mouseLeave() {
    setTimeout(() => {
      this.hoveringOverSchedule.set(false);
    }, 500);
  }

  onEditingRulesChange(active: boolean) {
    this.editingRules.set(active);
  }

  onPlaylistScheduleDateCheck(field: 'startDate' | 'endDate', event: Event) {
    const target = event.target as HTMLInputElement;
    const date =
      field === 'startDate'
        ? moment().utc(false).startOf('day').toISOString()
        : moment().utc(false).endOf('day').toISOString();
    if (target.checked === true) {
      this.playlistStore.setPlaylistStartEndDate(field, date);
    } else {
      this.playlistStore.setPlaylistStartEndDate(field, null);
    }
  }

  setScheduleDate(field: 'startDate' | 'endDate', value: string) {
    const year = +value.split('-')[0];
    const month = +value.split('-')[1];
    const day = +value.split('-')[2];
    const date =
      field === 'startDate'
        ? moment(this.playlistStore.playlist().schedule?.startDate)
            .set('year', year)
            .set('month', month - 1)
            .set('date', day)
            .toISOString()
        : moment(this.playlistStore.playlist().schedule?.endDate)
            .set('year', year)
            .set('month', month - 1)
            .set('date', day)
            .toISOString();
    console.log('setScheduleDate', field, date);
    this.playlistStore.setPlaylistStartEndDate(field, date);
  }

  onPlaylistScheduleTimeCheck(field: 'startDate' | 'endDate', event: Event) {
    const target = event.target as HTMLInputElement;
    const time =
      field === 'startDate'
        ? moment(this.playlistStore.playlist().schedule?.startDate)
            .utc(false)
            .set({ hour: 8, minute: 0, second: 0, millisecond: 0 })
        : moment(this.playlistStore.playlist().schedule?.endDate)
            .utc(false)
            .set({ hour: 17, minute: 0, second: 0, millisecond: 0 });
    if (target.checked === true) {
      this.playlistStore.setPlaylistStartEndDate(field, time.toISOString());
    } else {
      this.playlistStore.setPlaylistStartEndDate(
        field,
        field === 'startDate'
          ? moment(this.playlistStore.playlist().schedule?.startDate)
              .utc(false)
              .startOf('day')
              .toISOString()
          : moment(this.playlistStore.playlist().schedule?.endDate)
              .utc(false)
              .endOf('day')
              .toISOString(),
      );
    }
  }

  setScheduleTime(field: 'startDate' | 'endDate', value: string) {
    const hour = +value.split(':')[0];
    const minute = +value.split(':')[1];

    const schedule = this.playlistStore.playlist().schedule;

    const tmpTime =
      field === 'startDate' ? schedule?.startDate : schedule?.endDate;

    const time = moment(tmpTime).utc(false).set({
      hour,
      minute,
      second: 0,
      millisecond: 0,
    });
    // console.log('setScheduleTime', field, time.toISOString());
    this.playlistStore.setPlaylistStartEndDate(field, time.toISOString());
  }
}
