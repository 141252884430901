import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SubSink } from 'subsink';
import { FormDialogComponent } from '@desquare/components/common/src/modals/form-dialog.component';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
  standalone: true,
  imports: [ReactiveFormsModule, TranslatePipe, FormDialogComponent],
  selector: 'app-playlist-publishing-dialog',
  template: `<app-form-dialog
    [valid]="true"
    [useDangerButton]="false"
    closeButtonText="PUBLISH"
    dismissButtonText="CANCEL"
    headerText="PUBLISH_PLAYLIST"
    [values]="comment"
  >
    <form id="publishForm" [formGroup]="publishForm">
      @if (channels.length) {
        <div class="card mb-3 form-group">
          <h5 class="card-header bg-success">
            {{ 'channel.CHANNEL_ASSIGNED' | translate }}
          </h5>
          <div class="card-body prompt-details-success">
            <div class="card-title">
              {{ 'PUBLISH_PLAYLIST_PROMPT' | translate }}
            </div>
            <div class="overflow-auto limit">
              <ul class="list-group list-group-flush mt-2">
                @for (channel of channels; track channel) {
                  <li class="list-group-item text-white border-0">
                    {{ channel }}
                  </li>
                }
              </ul>
            </div>
          </div>
        </div>
      }

      @if (!channels.length || channelDifference.length) {
        <div class="card mb-3">
          <h5 class="card-header bg-danger rounded">
            {{
              (channelDifference.length
                ? 'PLAYLIST_CHANNEL_REMOVED_PROMPT'
                : 'PLAYLIST_NO_ASSIGNED_CHANNEL_PROMPT'
              ) | translate
            }}
          </h5>
          @if (channelDifference.length) {
            <div class="card-body prompt-details-danger">
              <div class="card-title">
                {{ 'PLAYLIST_CHANNEL_REMOVED_PROMPT_DETAILS' | translate }}
              </div>
              <div class="overflow-auto limit">
                <ul class="list-group list-group-flush mt-2">
                  @for (channel of channelDifference; track channel) {
                    <li class="list-group-item text-white ">
                      {{ channel }}
                    </li>
                  }
                </ul>
              </div>
            </div>
          }
        </div>
      }

      <div class="form-group">
        <label>{{ 'COMMENT' | translate }}</label>
        <input
          type="text"
          class="form-control form-control-dark"
          formControlName="comment"
          maxlength="100"
        />
      </div>
    </form>
  </app-form-dialog> `,
  styleUrls: ['./playlist-publishing-dialog.component.scss'],
})
export class PlaylistPublishingDialogComponent implements OnInit, OnDestroy {
  private subs = new SubSink();

  constructor(
    public modal: NgbActiveModal,
    private formBuilder: FormBuilder,
  ) {}

  playlistName!: string;
  activeChannels!: string[];
  channels!: string[];
  channelDifference!: string[];
  comment!: string;
  publishForm!: FormGroup;

  ngOnInit(): void {
    this.initForm();
    this.setFormState();
    this.channelDifference = this.getDifference();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  initForm() {
    this.publishForm = this.formBuilder.group({
      comment: [null],
    });
  }

  setFormState() {
    this.subs.sink = this.publishForm.valueChanges.subscribe(() => {
      this.comment = this.publishForm.value;
    });
  }

  /**
   * Gets the difference between the active channels and current channels of the playlist
   *
   * @returns
   */
  getDifference() {
    return this.activeChannels.filter((x) => !this.channels.includes(x));
  }
}
