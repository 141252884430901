import {
  Component,
  OnDestroy,
  ElementRef,
  OnInit,
  signal,
  computed,
  input,
  ChangeDetectionStrategy,
  viewChild,
  effect,
  untracked,
  inject,
} from '@angular/core';
import {
  AssetType,
  Maybe,
  ImageAsset,
  TransitionEffect,
  Playlist,
} from '@designage/gql';
import {
  PlaylistEditorService,
  AspectResizeCropService,
  TransitionService,
  LayoutDataService,
  PauseableTimerService,
} from '@desquare/services';
import {
  getBlobURL,
  screenUtil,
  getOptimizedUrl,
  isResourcePlayable,
} from '@desquare/utils';
import { IZoneResolution } from '@desquare/interfaces';
import { ResizeCropMethodURIs, TransitionEffects } from '@desquare/enums';
import moment from 'moment';
import { LocalStorageService } from 'ngx-webstorage';
import { localStorageKeys } from '@desquare/constants';
import { environment } from '@desquare/environments';
import { TranslatePipe } from '@ngx-translate/core';
import { SafePipe } from '@desquare/components/common/src/pipe/safe/safe.pipe';
import { LoaderComponent } from '@desquare/components/common/src/loader/loader.component';
import { PlaylistAssetItem } from '@desquare/types';
import { PlaylistPreviewStore } from '@desquare/stores';

// const SIZE_100MB = 100000000; // 100MB
@Component({
  standalone: true,
  imports: [TranslatePipe, SafePipe, LoaderComponent],
  selector: 'app-region-player',
  template: `<div
    class="d-flex flex-column h-100 overflow-auto"
    #previewContainer
  >
    @if (contents() && contents().length === 0) {
      <h5 class="content-text">
        {{ 'NO_ADDED_ITEM_TO_PREVIEW' | translate }}
      </h5>
    }
    <img
      #previewImage1
      id="previewImage1"
      (click)="togglePlayback()"
      class="content"
      [class.show]="currentContent()?.type === assetType.Image"
    />
    <img
      #previewImage2
      id="previewImage2"
      (click)="togglePlayback()"
      class="content"
      [class.show]="currentContent()?.type === assetType.Image"
    />
    @if (
      currentVideoUri() !== '' && currentContent()?.__typename === 'VideoAsset'
    ) {
      <video
        [muted]="isMuted()"
        #video
        id="video"
        (click)="togglePlayback()"
        (ended)="onVideoEnded()"
        class="content"
        [class.show]="currentContent()?.type === assetType.Video"
        [src]="currentVideoUri()"
        autoplay="true"
      ></video>
    }
    @if (currentContent()?.type === assetType.Html) {
      <iframe
        class="content"
        scrolling="yes"
        [src]="currentHtmlContent() | safe: 'resourceUrl'"
      ></iframe>
    }
    @if (!loading() && currentContent() === null) {
      <h4 class="text-center align-middle">
        {{ 'NO_ITEM_PLAYLIST_TO_PLAY' | translate }}
      </h4>
    }
    @if (loading()) {
      <app-loader
        class="flex-grow-1"
        [message]="'PREVIEW_LOADING' | translate"
      />
    }
  </div> `,
  styleUrls: ['./region-player.component.scss'],
  providers: [PauseableTimerService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegionPlayerComponent implements OnInit, OnDestroy {
  timerService = inject(PauseableTimerService);
  playlistPreviewStore = inject(PlaylistPreviewStore);

  video = viewChild<ElementRef<HTMLVideoElement>>('video');
  previewImage1 = viewChild<ElementRef<HTMLImageElement>>('previewImage1');
  previewImage2 = viewChild<ElementRef<HTMLImageElement>>('previewImage2');
  previewContainer = viewChild<ElementRef<HTMLDivElement>>('previewContainer');

  playlists = input.required<Playlist[]>();
  zoneResolution = input.required<IZoneResolution>();

  /** there is some content but nothing should at this date/time */
  noScheduledContent = signal(false);

  /** list of asset types for template */
  assetType = AssetType;

  /** all available contents */
  contents = computed(() => {
    const playlists = this.playlists();
    const contents: PlaylistAssetItem[] = [];
    for (const p of playlists) {
      const playlistStart = p.schedule?.startDate
        ? moment(p.schedule?.startDate).utc(false).format('YYYY-MM-DDTHH:mm:ss')
        : null;
      const playlistEnd = p.schedule?.endDate
        ? moment(p.schedule?.endDate).utc(false).format('YYYY-MM-DDTHH:mm:ss')
        : null;
      const playlistAssetsCount = p.assets.length;
      for (const a of p.assets) {
        const { actualStartTime, actualEndTime } = a;
        for (const c of a.content || []) {
          contents.push({
            ...c,
            playlistAssetsCount,
            playlistStart,
            playlistEnd,
            actualStartTime,
            actualEndTime,
          });
        }
      }
    }
    this.currentContentIdx = -1;
    return contents;
  });

  /** currently playing */
  currentContentIdx = -1;
  /** currently playing */
  currentContent = this.playlistPreviewStore.currentContent;
  loading = signal(true);

  currentImageDuration = signal<number>(10);
  currentVideoUri = computed(() => {
    const content = this.currentContent();
    if (content && content.__typename === 'VideoAsset' && 'uri' in content) {
      const resizeCropMethod =
        content.resizeCropMethod?.uri || ResizeCropMethodURIs.PAD_URI;
      // const mediaSize = content.media?.metadata?.bytes || 0;
      // const applyTransformation = mediaSize <= SIZE_100MB ? true : false;
      const applyTransformation = true;
      this.hideImage();
      return content.uri
        ? this.getResourceUrl(
            AssetType.Video,
            content.uri,
            resizeCropMethod,
            applyTransformation,
          )
        : environment.assets.placeholderImage;
    }
    return '';
  });
  currentClVideoId!: Maybe<string>;
  currentHtmlContent = computed(() => {
    const content = this.currentContent();
    if (
      !!content &&
      content.type === AssetType.Html &&
      'htmlContent' in content
    ) {
      return getBlobURL(content.htmlContent, 'text/html');
    }
    return '';
  });

  progressPercentage = computed((): number => {
    const percent = Number(
      (
        ((this.timerService.elapsedTimeValue() ?? 0) /
          (this.currentContent()?.duration ?? 1)) *
        100
      ).toFixed(2),
    );
    return percent;
  });

  durationTimeoutId!: Maybe<number>;

  simulateDateTime = input.required<Maybe<moment.Moment>>();

  isMuted = signal(true);
  isPlaying = signal(true);

  prevContents = signal<PlaylistAssetItem[] | null>(null);
  prevTime = signal<Maybe<moment.Moment>>(null);

  currentPlayingImageElement!: string;
  previousTransitionEffect!: string;
  previousTransitionDuration!: number;

  isFullscreen = signal(false);
  previewState!: {
    previewResolution: IZoneResolution;
    /** channel id */
    selectedChannel?: string;
  };

  constructor(
    public playlistEditorService: PlaylistEditorService,
    private localStorageService: LocalStorageService,
    public layoutService: LayoutDataService,
    public arcService: AspectResizeCropService,
    private transitionService: TransitionService,
  ) {
    effect(() => {
      const contents = this.contents();
      const time = this.simulateDateTime();
      untracked(() => {
        // Only clear preview if contents or time has actually changed
        const contentsChanged =
          JSON.stringify(contents) !== JSON.stringify(this.prevContents());
        const timeChanged = time?.valueOf() !== this.prevTime()?.valueOf();

        if (contentsChanged || timeChanged) {
          if (contents || time) {
            this.clearPreview();
          }
          // Update previous values
          this.prevContents.set(contents);
          this.prevTime.set(time);
        }
      });
    });
  }

  ngOnInit() {
    this.getPreviewState();
    this.startPlay();
  }

  ngOnDestroy() {
    this.timerService.reset();
    this.playlistPreviewStore.clearContentInPreview();
    this.clearPreviewElements();
  }

  getPreviewState() {
    this.previewState = this.localStorageService.retrieve(
      localStorageKeys.ACTIVE_PREVIEW_RESOLUTION,
    );
    if (this.previewState != null) {
      return;
    }
    this.previewState = {
      previewResolution: { ...this.arcService.getPlaylistAspect() },
    };
  }

  clearPreviewElements() {
    if (this.previewImage1()) this.previewImage1()!.nativeElement.src = '';
    if (this.previewImage2()) this.previewImage2()!.nativeElement.src = '';
    this.previousTransitionEffect = 'None';
    this.currentContentIdx = -1;
  }

  async startPlay() {
    const nextIdx = this.findNextResourceIdx(this.currentContentIdx);
    await this.play(nextIdx);
  }

  /** play an item or show NO CONTENT (index=-1) */
  async play(contentIndex: number) {
    // this.clearContentUri();
    this.loading.set(false);
    if (contentIndex < 0) {
      // this.noScheduledContent.set(true);
      this.playlistPreviewStore.clearContentInPreview();
      return;
    }
    this.noScheduledContent.set(false);

    const contents = this.contents();
    if (contentIndex > contents.length) {
      contentIndex = 0;
    }
    const content = contents[contentIndex];
    this.currentContentIdx = contentIndex;
    const previousVideoUrl = this.currentVideoUri();
    this.playlistPreviewStore.setContentInPreview(content);

    if (!content.__typename) {
      content.__typename = this.getContentTypeName(content.type);
    }
    if (content.__typename === 'VideoAsset' && content.duration) {
      if (previousVideoUrl === this.currentVideoUri()) {
        // same video, manual restart
        this.video()?.nativeElement.play();
      }
      this.timerService.start(content.duration);
    }
    if (
      content.__typename === 'ImageAsset' &&
      'uri' in content &&
      content.duration
    ) {
      this.previousTransitionDuration = this.previousTransitionDuration || 0;

      const elementToDisplay = this.getImageElementToDisplay();
      this.prepareContent(elementToDisplay, content);
      this.transition(
        elementToDisplay,
        this.getImageElementToHide(),
        content.transitionEffect || undefined,
      );

      await this.timerService.start(content.duration);

      this.currentPlayingImageElement = elementToDisplay;
      this.previousTransitionEffect = content.transitionEffect?.name || 'None';
      this.previousTransitionDuration = content.transitionEffect?.duration || 0;
      this.play(this.findNextResourceIdx(this.currentContentIdx));
    }

    if (
      content.type === AssetType.Html &&
      'htmlContent' in content &&
      content.duration
    ) {
      console.log('Starting html:', content.name, content.duration);
      await this.timerService.start(content.duration);
      console.log('Timer reached for:', content.name);
      this.play(this.findNextResourceIdx(this.currentContentIdx));
    }
    // Original cloudinary URL version of Video tag

    // prevent content from playing when paused and toggled published/preview
    if (!this.isPlaying) {
      this.togglePlayback(false);
    }
  }

  waitDuration(ms: number) {
    return new Promise(
      (resolve) => (this.durationTimeoutId = window.setTimeout(resolve, ms)),
    );
  }

  onVideoEnded() {
    this.play(this.findNextResourceIdx(this.currentContentIdx));
  }

  clearPreview() {
    this.timerService.reset();
    this.playlistPreviewStore.clearContentInPreview();
    this.clearPreviewElements();
    this.startPlay();
  }

  togglePlayback(boolean?: boolean) {
    if (this.isPlaying()) {
      if (this.currentVideoUri() && !this.video()?.nativeElement?.paused) {
        setTimeout(() => {
          this.video()?.nativeElement?.pause();
        }, 100);
      }
      this.timerService.pause();
      // this.timer?.pause();
    } else {
      if (this.currentVideoUri() && this.video()?.nativeElement?.paused) {
        this.video()?.nativeElement?.play();
      }
      this.timerService.resume(this.currentContent()?.duration ?? 0);
    }
    if (boolean !== undefined) {
      return this.isPlaying.set(boolean);
    }
    this.isPlaying.set(!this.isPlaying());
  }

  toggleFullScreen(isFullScreenTrigger: boolean) {
    if (isFullScreenTrigger && !this.isFullscreen()) {
      this.requestFullScreen();
    } else {
      this.exitFullScreen();
    }
  }

  requestFullScreen() {
    const previewComponent = this.previewContainer()?.nativeElement;
    if (!previewComponent) return console.error('previewContainer not found');
    screenUtil.requestFullScreen(previewComponent);
    this.isFullscreen.set(true);
  }

  exitFullScreen() {
    screenUtil.exitFullScreen();
    this.isFullscreen.set(false);
  }

  getImageElementToDisplay() {
    if (this.currentPlayingImageElement === 'previewImage1') {
      return 'previewImage2';
    } else {
      return 'previewImage1';
    }
  }

  getImageElementToHide() {
    if (this.currentPlayingImageElement === 'previewImage1') {
      return 'previewImage1';
    } else {
      return 'previewImage2';
    }
  }

  prepareContent(elementToDisplay: string, content: ImageAsset) {
    const resizeCropMethod =
      content.resizeCropMethod?.uri || ResizeCropMethodURIs.PAD_URI;
    if (this.previewImage1() && elementToDisplay === 'previewImage1') {
      this.previewImage1()!.nativeElement.src = content.uri
        ? this.getResourceUrl(AssetType.Image, content.uri, resizeCropMethod)
        : environment.assets.placeholderImage;
    } else if (this.previewImage2() && elementToDisplay === 'previewImage2') {
      this.previewImage2()!.nativeElement.src = content.uri
        ? this.getResourceUrl(AssetType.Image, content.uri, resizeCropMethod)
        : environment.assets.placeholderImage;
    }
  }

  async transition(
    elementToDisplay: string,
    elementToHide: string,
    transition?: TransitionEffect,
  ) {
    if (this.previewImage1() && this.previewImage2()) {
      if (transition?.name === TransitionEffects.FADE) {
        const duration = (transition?.duration || 0) / 1000;
        await this.transitionService.fade(
          '#'.concat(elementToDisplay),
          '#'.concat(elementToHide),
          duration,
        );
      } else {
        await this.transitionService.cut(
          '#'.concat(elementToDisplay),
          '#'.concat(elementToHide),
        );
      }
    }
  }

  hideImage() {
    if (this.previewImage1())
      this.transitionService.hideElements(['#previewImage1']);
    if (this.previewImage2())
      this.transitionService.hideElements(['#previewImage2']);
  }

  getContentTypeName(type: AssetType) {
    switch (type) {
      case AssetType.Image:
        return 'ImageAsset';
      case AssetType.Video:
        return 'VideoAsset';
      case AssetType.Html:
        return 'HtmlAsset';
      default:
        return undefined;
    }
  }

  getResourceUrl(
    assetType: AssetType,
    contentUri: string,
    resizeCropMethod: string,
    applyTransformation: boolean = true,
  ) {
    return applyTransformation
      ? getOptimizedUrl(
          assetType,
          contentUri,
          this.zoneResolution(),
          resizeCropMethod,
        )
      : getOptimizedUrl(assetType, contentUri);
  }

  /** returns simulating date or now */
  getSimulateDateOrNow() {
    return this.simulateDateTime()?.toDate() || new Date();
  }

  /** find next playable item index, copied from Applet 3.3.9 */
  findNextResourceIdx(currentIndex: number) {
    // NOTE: we use resources.length + 1 to reconsider current item that could be the only active
    const arraySize = this.contents().length;
    if (arraySize < 1) return -1;

    const maxSteps = arraySize + 1;

    for (let step = 1; step <= maxSteps; step++) {
      const index = (currentIndex + step) % arraySize;
      if (
        this.contents()[index] &&
        isResourcePlayable(this.contents()[index], this.getSimulateDateOrNow())
      ) {
        return index;
      }
    }
    // nothing to play!
    return -1;
  }
}
