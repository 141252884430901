import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
  selector: 'des-validation-message',
  standalone: true,
  imports: [TranslatePipe],
  template: `
    <div class="position-absolute  validation-badge" [class]="computedClass()">
      <span>{{ message() | translate }}</span>
    </div>
  `,
  styleUrls: ['./des-ui-components.component.scss'],

  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DesValidationMessageComponent {
  message = input('');
  color = input<
    | 'primary'
    | 'secondary'
    | 'success'
    | 'danger'
    | 'warning'
    | 'info'
    | 'light'
    | 'dark'
    | 'link'
  >('danger');

  computedClass = computed(() => {
    return `bg-${this.color()}`;
  });
}
