@if (!readOnly()) {
  <div
    [ngClass]="{
      expanded: contentItem().isOpen,
      'pop-settings-active': showPopSettings() && contentItem().isOpen,
    }"
    class="content-row content-row-expanded"
    [class.item-disabled]="contentItem().disabled"
    [class.content-in-preview]="isInPreview()"
  >
    <div
      class="d-flex align-items-center justify-content-end name-field"
      [class.item-disabled]="contentItem().disabled"
      (click)="onRowClick()"
    >
      <div class="align-items-center icons item-header">
        <div>
          @if (!contentItem().disabled) {
            <span
              [ngStyle]="activityStatus().style"
              [ngbTooltip]="activityStatus().status"
              (click)="onRowClick()"
              class="status-indicator"
            ></span>
          }
        </div>
        <div class="content-type d-flex align-items-center">
          <ng-container *ngTemplateOutlet="typeIndicator"></ng-container>
        </div>
      </div>
      <div class="name-container overflow-hidden flex-grow-1">
        @if (isEditingName) {
          <input
            #nameInput
            class="form-control form-control-dark edit-content-name"
            autocomplete="off"
            [(ngModel)]="contentItem().name"
            (blur)="toggleEdit()"
            (keydown.enter)="toggleEdit()"
            (keydown.esc)="toggleEdit()"
            (click)="$event.stopPropagation()"
            (mousedown)="$event.stopPropagation()"
            required
            pattern=".{3,}"
          />
        }
        <div class="content-name" [class.input-group]="isEditingName">
          @if (!isEditingName) {
            <h4
              class="text-truncate"
              (click)="editName(); $event.stopPropagation()"
            >
              {{ contentItem().name }}
            </h4>
            @if (!readOnly()) {
              <span class="icon">
                <i
                  (click)="editName(); $event.stopPropagation()"
                  class="ri-pencil-fill text-primary"
                ></i>
              </span>
            }
          }
        </div>
      </div>

      <div class="d-flex align-items-center">
        <!-- MARK: Disable Switch -->
        @if (contentItem().disabled) {
          <h5
            ngbTooltip="{{ 'PLAYLIST_DISABLE_ITEM_TT' | translate }}"
            class="badge bg-danger"
          >
            {{ 'DISABLED' | translate }}
          </h5>
        }
        <div
          ngbTooltip="{{ 'PLAYLIST_ENABLE_ITEM_SWITCH_TT' | translate }}"
          class="deactivate-switch form-check form-switch"
          (click)="$event.stopPropagation(); onDisableSwitch()"
        >
          <input
            class="form-check-input"
            type="checkbox"
            name="disableContentItem"
            [checked]="!contentItem().disabled"
          />
        </div>
        <i class="mx-2 ri-arrow-up-s-line"></i>

        <!-- MARK: Pin Container -->
        <div
          class="pin-container"
          (click)="onRowPin(); $event.stopPropagation()"
          ngbTooltip="{{ 'PIN_PL_ROW' | translate }}"
          [disableTooltip]="contentItem().isPinned || false"
          [ngClass]="contentItem().isPinned ? 'pin-pinned' : 'pin-unpinned'"
        >
          <i
            [ngClass]="contentItem().isPinned ? 'ti-pin2' : 'ti-pin-alt'"
            class="mx-2 ti"
          ></i>
        </div>
      </div>
    </div>

    <!-- MARK: Content Preview -->
    <div class="content-preview">
      <div class="image-container">
        @if (isVideo || isImage) {
          <img
            class="media-preview"
            [src]="thumbnailUrl"
            (click)="openPreviewDialog(); $event.stopPropagation()"
          />
        }
        @if (isHtml) {
          <iframe
            class="html-preview-container"
            scrolling="no"
            [src]="htmlUrl | safe: 'resourceUrl'"
          ></iframe>
        }
        @if (isIFrame) {
          <div class="d-flex align-items-center flex-column mw-100">
            <div class="d-flex">
              <div
                style="font-size: 3rem"
                class="align-self-center ti-html5"
              ></div>
              <h3 class="">HTML / IFrame Widget</h3>
            </div>
            <p
              class="pt-2 d-inline-block text-truncate mw-100"
              ngbTooltip="{{ assetHtmlItem().htmlContent }}"
            >
              {{ assetHtmlItem().htmlContent }}
            </p>
          </div>
        }
      </div>
    </div>

    <!-- MARK: Schedule -->
    <div class="content-schedule">
      <div class="start-date">
        <div class="start-date-toggle" (click)="onCheckBoxStartDate()">
          <input
            class="checkbox start-date-checkbox"
            type="checkbox"
            [checked]="isStartDateChecked()"
          />
          <label class="form-label start-date-label detail-info"
            >{{ 'DO_NOT_PLAY_BEFORE' | translate }}:</label
          >
        </div>
        <div class="start-date-picker date-picker">
          <designage-datepicker
            [disabled]="!isStartDateChecked()"
            [date]="campaignStartValue()"
            [maxDate]="campaignEndValue()"
            (dateChange)="onCampaignStartClose($event)"
          />
        </div>
      </div>
      <div class="end-date">
        <div class="end-date-toggle" (click)="onCheckBoxEndDate()">
          <input
            class="checkbox end-date-checkbox"
            type="checkbox"
            [checked]="isEndDateChecked()"
          />
          <label class="form-label end-date-label detail-info"
            >{{ 'DO_NOT_PLAY_AFTER' | translate }}:</label
          >
        </div>
        <div class="end-date-picker date-picker">
          <designage-datepicker
            [disabled]="!isEndDateChecked()"
            [date]="campaignEndValue()"
            [minDate]="campaignStartValue()"
            (dateChange)="onCampaignEndClose($event)"
          />
        </div>
      </div>
      <div class="weekdays">
        <label class="form-label detail-info d-block"
          >{{ 'PLAY_SEQUENCE_ON' | translate }}:</label
        >
        <div class="">
          <ng-container *ngTemplateOutlet="topRightControls" />
        </div>
      </div>

      <!-- MARK: Duration -->
      <div class="content-duration overflow-hidden">
        <label class="ps-2 form-label detail-info"
          >{{ 'DURATION' | translate }}:</label
        >
        <label class="ps-2 form-label detail-info">
          {{ durationMs | duration }}
        </label>
        @if (contentItem().type === assetType.Video) {
          <br />
          <div
            class="ps-2 text-muted text-truncate"
            [ngbTooltip]="'DURATION_DERIVED_FROM_FILE' | translate"
          >
            {{ 'DURATION_DERIVED_FROM_FILE' | translate }}
          </div>
        } @else {
          <div
            class="time-picker-box"
            [class.validation-border]="!validFields().tests['duration'].valid"
          >
            <designage-timepicker
              [(time)]="timeDurationInput"
              (click)="$event.stopPropagation()"
              (timeChange)="onDurationInputClose()"
            />
            <!-- @if (!validDurationFormat) {
              <span class="me-1 time-error text-danger">{{
                'DURATION_FORMAT_ERROR' | translate
              }}</span>
            } -->
            <!-- @if (!validDurationValue) {
              <span class="time-error text-danger">{{
                'DURATION_BELOW_MIN_ERROR' | translate
              }}</span>
            } -->
            @if (!validFields().tests['duration'].valid) {
              <des-validation-message
                [message]="validFields().tests['duration'].errors[0]"
                [color]="'danger'"
              />
            }
          </div>
        }
      </div>

      <!-- MARK: Resize and Crop -->
      @if (isAssetVideoOrImage()) {
        <div class="gridResize">
          <div
            class="start-date-toggle"
            (click)="disableResizeCrop() ? null : onCheckBoxResizeCrop()"
          >
            <input
              [disabled]="disableResizeCrop()"
              class="checkbox start-date-checkbox"
              type="checkbox"
              [checked]="!disableResizeCrop() && isResizeCropChecked"
            />
            <label
              class="form-label"
              [class.text-secondary]="disableResizeCrop()"
            >
              {{ 'RESIZE_CROP' | translate }}:
            </label>
          </div>
          <div ngbDropdown container="body">
            <input
              type="button"
              [disabled]="disableResizeCrop() || !isResizeCropChecked"
              class="form-control form-control-dark dropdown"
              ngbDropdownToggle
              value="{{ resizeCropMethod() | translate }}"
            />
            <div ngbDropdownMenu class="shadowed-box text-white">
              @for (method of resizeCropMethods; track method) {
                @if (method !== resizeCropMethod()) {
                  <span
                    ngbDropdownItem
                    class="dropdown-items"
                    (click)="setResizeCrop(method)"
                    >{{ method | translate }}</span
                  >
                }
              }
            </div>
          </div>
          <div
            class="position-absolute form-check form-switch"
            (click)="onDisableOptimizedUrl(!optimizedUrlDisabled())"
            ngbTooltip="{{ 'MEDIA.MEDIA_OPTIMIZATION_TT' | translate }}"
          >
            <input
              class="form-check-input"
              type="checkbox"
              [checked]="optimizedUrlDisabled()"
            />
            <label class="fs-6 form-check-label">{{
              'MEDIA.MEDIA_OPTIMIZATION' | translate
            }}</label>
          </div>
        </div>
      }

      <!-- MARK: Transition -->
      @if (showTransition()) {
        <div class="gridTransitionEffect">
          <label class="ps-2 form-label detail-info"
            >{{ 'TRANSITION' | translate }}:</label
          >
          <div class="duration-row">
            <div ngbDropdown container="body">
              <input
                type="button"
                class="form-control form-control-dark dropdown"
                ngbDropdownToggle
                value="{{ transitionEffect() | translate }}"
              />
              <div ngbDropdownMenu class="shadowed-box text-white">
                @for (effect of transitionEffects; track effect) {
                  @if (effect !== transitionEffect()) {
                    <span
                      ngbDropdownItem
                      class="dropdown-items"
                      (click)="setSelectedTransition(effect)"
                      >{{ effect | translate }}</span
                    >
                  }
                }
              </div>
            </div>
            @if (transitionEffect() != 'CUT') {
              <div
                style="height: 2rem; min-width: 6rem"
                class="w-100 d-flex ms-2"
                (mousedown)="$event.stopPropagation()"
              >
                <input
                  class="form-control form-control-dark dropdown"
                  type="number"
                  [value]="transitionDurationSecs()"
                  (change)="onTransitionDurationChange($event)"
                  min="0.25"
                  [(max)]="durationSecs"
                  step="0.25"
                  placeholder="0.00"
                />
                <label style="width: auto; margin: auto 0 0 0" class="px-1"
                  >s</label
                >
              </div>
            }
          </div>
        </div>
      }

      <!-- MARK: Media Optimization -->
      <!-- @if (isAssetVideoOrImage()) {
        <div
          class="gridOptimiztion"
          style="grid-column: 2 / 4"
          [ngbTooltip]="'MEDIA.MEDIA_OPTIMIZATION_TT' | translate"
        >
          <div
            class="d-flex align-items-center"
            (click)="onDisableOptimizedUrl(!optimizedUrlDisabled())"
          >
            <input
              class="checkbox"
              type="checkbox"
              [checked]="optimizedUrlDisabled()"
            />
            <label class="ps-2 form-label detail-info"
              >{{ 'MEDIA.MEDIA_OPTIMIZATION' | translate }}:</label
            >
          </div>
          <div ngbDropdown container="body">
            <input
              type="button"
              class="form-control form-control-dark dropdown"
              ngbDropdownToggle
              value="{{
                assetImageItem().disableOptimization
                  ? ('MEDIA.MEDIA_OPTIMIZATION_DISABLE_CONTENT_OPTIMIZATION'
                    | translate)
                  : ('MEDIA.MEDIA_OPTIMIZATION_ENABLE_CONTENT_OPTIMIZATION'
                    | translate)
              }}"
            />
            <div ngbDropdownMenu class="shadowed-box text-white">
              <span
                ngbDropdownItem
                class="dropdown-items"
                (click)="onDisableOptimizedUrl(contentItem().type, false)"
                >{{
                  'MEDIA.MEDIA_OPTIMIZATION_ENABLE_CONTENT_OPTIMIZATION' | translate
                }}</span
              >
              <span
                ngbDropdownItem
                class="dropdown-items"
                (click)="onDisableOptimizedUrl(contentItem().type, true)"
                >{{
                  'MEDIA.MEDIA_OPTIMIZATION_DISABLE_CONTENT_OPTIMIZATION' | translate
                }}</span
              >
            </div>
          </div>
        </div>
      } -->
    </div>

    @if (showPopSettings()) {
      <div
        class="pop-settings bg-secondary"
        [class.bg-pop-tracked]="popEnabled()"
      >
        <div
          class="d-flex align-items-center"
          (click)="onPopSwitch()"
          [ngbTooltip]="'POP_ITEM_TRACKED_TT' | translate"
        >
          <input class="checkbox" type="checkbox" [checked]="popEnabled()" />
          <label class="text-nowrap form-label detail-info me-2">
            {{ 'POP_ITEM_TRACKED' | translate }}:
          </label>
        </div>
        <input
          #popTrackIdInput
          type="text"
          class="form-control form-control-dark"
          autocomplete="off"
          placeholder="Track ID"
          [ngModel]="popId()"
          (blur)="setPopTrackId($event)"
          [disabled]="!popEnabled()"
        />
      </div>
    }

    <div class="control-buttons d-flex justify-content-end align-items-center">
      @if (
        (contentItem().type === assetType.Image ||
          assetType.Iframe ||
          assetType.Video) &&
        assetImageItem().media?.metadata?.format != 'pdf'
      ) {
        <button
          type="button"
          class="m-2 border btn btn-sm btn-outline-primary border-primary"
          ngbTooltip="{{ 'EDIT_ITEM_CONTENT_TOOLTIP' | translate }}"
          (click)="onEditContent(); $event.stopPropagation()"
        >
          {{ 'EDIT_ITEM_CONTENT' | translate }}
        </button>
      }
      <button
        type="button"
        class="m-2 border btn btn-sm btn-outline-primary border-primary"
        (click)="onReplaceContent(); $event.stopPropagation()"
      >
        {{ 'REPLACE_CONTENT' | translate }}
      </button>
      @if (isInteractive && !isInteractionTargetMedia) {
        <button
          type="button"
          class="m-2 border btn btn-sm btn-outline-primary border-primary"
          (click)="onEditInteractiveActions(); $event.stopPropagation()"
        >
          {{ 'EDIT_ACTIONS' | translate }}
        </button>
      }
      @if (!isInteractive) {
        <button
          type="button"
          class="m-2 border btn btn-sm btn-outline-primary border-primary"
          (click)="onDuplicate(); $event.stopPropagation()"
        >
          {{ 'DUPLICATE' | translate }}
        </button>
      }
      @if (!isInteractive) {
        <button
          type="button"
          class="m-2 border btn btn-outline-danger btn-sm border-danger"
          (click)="onDelete(); $event.stopPropagation()"
        >
          {{ 'DELETE_PLAYLIST_ITEM' | translate }}
        </button>
      }
    </div>
  </div>
}

<!-- MARK: collapsed view -->
<div
  [hidden]="readOnly() ? false : contentItem().isOpen || false"
  class="content-row content-row-collapsed"
  [class.item-disabled]="contentItem().disabled"
  [class.content-in-preview]="isInPreview()"
  [class.channel-view]="channelView()"
  [openDelay]="1000"
  placement="start"
  ngbTooltip="{{ contentItem().disabled ? ('DISABLED' | translate) : '' }}"
  (click)="!readOnly() && onRowClick()"
>
  <div class="icons z-1">
    @if (!contentItem().disabled) {
      <span
        [ngStyle]="activityStatus().style"
        [ngbTooltip]="activityStatus().status"
        class="status-indicator"
      ></span>
    }
    <ng-container *ngTemplateOutlet="typeIndicator"></ng-container>
  </div>

  <div class="image-container">
    @if (
      contentItem().type === assetType.Video ||
      contentItem().type === assetType.Image
    ) {
      <img
        class="content-thumbnail z-1"
        [src]="thumbnailUrl"
        (click)="openPreviewDialog(); $event.stopPropagation()"
      />
    }
    @if (contentItem().type === assetType.Html) {
      <i class="ri-layout-line"></i>
    }
  </div>
  <div class="title-might-overflow" [class.my-0]="popEnabled()">
    <p class="d-inline text-nowrap p-2">
      {{ title }}
    </p>
    @if (popEnabled()) {
      <div
        class="position-absolute bg-pop-tracked py-0 px-2 rounded"
        style="font-size: 0.6rem !important"
      >
        {{ 'POP_ITEM_TRACKED' | translate }}:
        <span class="badge bg-dark" style="font-size: 0.6rem !important">
          {{ popId() }}
        </span>
      </div>
    }
  </div>
  <div class="metadata text-center">
    @if (!contentItem().disabled) {
      <div>
        <span>
          <!-- {{ 'DURATION' | translate }}: -->
          <i class="ri-timer-line text-white"></i>
        </span>
        <span>{{ durationMs | duration }}</span>
        @if (!validDurationValue || !validDurationFormat) {
          <span class="text-danger">{{ 'INVALID_DURATION' | translate }}</span>
        }
      </div>
    }
  </div>
  <div class="metadata">
    @if (!contentItem().disabled && dateDuration !== '') {
      <div class="badge z-1" [ngClass]="activityStatus().class">
        <i class="ri-calendar-todo-line text-white pe-1"></i>{{ dateDuration }}
      </div>
    }
  </div>
  <div class="detail-row">
    @if (notAllDays && !contentItem().disabled) {
      <div class="detail-row z-1">
        <ng-container *ngTemplateOutlet="topRightControls"></ng-container>
      </div>
    }
  </div>

  <div class="metadata">
    @if (!readOnly()) {
      <i
        class="delete mdi mdi-delete text-danger z-1"
        (click)="onDelete(); $event.stopPropagation()"
      ></i>
    }
  </div>
</div>

<ng-template #topRightControls>
  <!-- slice pipe to exclude __typename -->
  <div></div>
  @for (day of scheduleDays() | slice: 0 : 7; track day.key; let i = $index) {
    <label
      class="day"
      [ngStyle]="day.style"
      (click)="!readOnly() && switchDayStatus(i); $event.stopPropagation()"
    >
      {{ day.code }}
    </label>
  }
</ng-template>

<ng-template #typeIndicator>
  @if (contentItem().type === assetType.Video) {
    <div>
      @if (!contentItem().isOpen || readOnly()) {
        <p class="d-inline-block m-auto px-1">
          {{ 'VID' | translate }}
        </p>
      } @else {
        <i class="ri-video-fill"></i>
        <p class="d-inline-block m-auto px-1">
          {{ 'VIDEO' | translate }}
        </p>
      }
    </div>
  }
  @if (
    contentItem().type === assetType.Image &&
    assetImageItem().media?.metadata?.format != 'pdf'
  ) {
    <div>
      @if (!contentItem().isOpen || readOnly()) {
        <p class="d-inline-block m-auto px-1">
          {{ 'IMG' | translate }}
        </p>
      } @else {
        <i class="ri-image-fill"></i>
        <p class="d-inline-block m-auto px-1">
          {{ 'IMAGE' | translate }}
        </p>
      }
    </div>
  }
  @if (
    contentItem().type === assetType.Image &&
    assetImageItem().media?.metadata?.format === 'pdf'
  ) {
    <div>
      @if (!contentItem().isOpen || readOnly()) {
        <p class="d-inline-block m-auto px-1">
          {{ 'PDF' | translate }}
        </p>
      } @else {
        <i class="ri-file-pdf-2-fill"></i>
        <p class="d-inline-block m-auto px-1">{{ 'PDF' | translate }}</p>
      }
    </div>
  }
  @if (
    contentItem().type === assetType.Html ||
    contentItem().type === assetType.Iframe
  ) {
    <div>
      @if (!contentItem().isOpen || readOnly()) {
        <p class="d-inline-block m-auto px-1">
          {{ 'HTML' | translate }}
        </p>
      } @else {
        <i class="ri-html5-fill"></i>
        <p class="d-inline-block m-auto px-1">{{ 'HTML' | translate }}</p>
      }
    </div>
  }
</ng-template>
