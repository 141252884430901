import { environment } from '@desquare/environments';

// size can be of type any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function formatFileSize(size: any) {
  if (size >= 1073741824) {
    size = (size / 1073741824).toFixed(2) + ' GB';
  } else if (size >= 1048576) {
    size = (size / 1048576).toFixed(2) + ' MB';
  } else if (size >= 1024) {
    size = (size / 1024).toFixed(2) + ' KB';
  } else if (size > 1) {
    size = size + ' bytes';
  } else if (size === 1) {
    size = size + ' byte';
  } else {
    size = size + ' bytes';
  }
  return size;
}

export function formatFileName(fileName: string) {
  return fileName.substring(0, fileName.lastIndexOf('.'));
}

export function validateFileFormat(fileName: string) {
  const format = fileName.split('.').pop();
  if (format) {
    return environment.file.formats.includes(format);
  }
  return false;
}

export function getBlobURL(code: string, type: string) {
  const blob = new Blob([code], { type });
  return URL.createObjectURL(blob);
}
