import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Playlist } from '@designage/gql';
import { ConfirmDialogComponent } from '@desquare/components/common/src/modals/confirm-dialog.component';
import { DateProxyPipe } from '@desquare/components/common/src/pipe/pipe/date-proxy.pipe';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { TranslatePipe } from '@ngx-translate/core';
import { MomentModule } from 'ngx-moment';

@Component({
  standalone: true,
  imports: [
    TranslatePipe,
    ConfirmDialogComponent,
    DateProxyPipe,
    MomentModule,
    NgbTooltip,
  ],
  selector: 'app-revert-playlist-dialog',
  template: `<app-confirm-dialog
    [closeButtonEnabled]="true"
    [useWarningButton]="true"
    headerText="REVERT_PLAYLIST"
    closeButtonText="REVERT"
    dismissButtonText="CANCEL"
  >
    <div class="card mb-3">
      <div class="card-body border border-warning rounded">
        <ul class="list-group list-group-flush my-3">
          <li class="list-group-item text-white pt-2 rounded">
            <span class="fw-bold mb-2"
              >{{ 'REVERT_PLAYLIST' | translate }}
              {{ selectedPlaylist.name }}?</span
            >
            <br />

            <span
              class=" text-nowrap fs-5"
              [ngbTooltip]="selectedPlaylist.updatedAt | date: 'medium'"
            >
              {{ 'REVERT_PLAYLIST_UPDATED_AT' | translate }}
              {{ selectedPlaylist.updatedAt | amTimeAgo }}
            </span>
          </li>
        </ul>
        <p>
          {{ 'REVERT_PLAYLIST_PROMPT_DETAILS' | translate }}
        </p>
      </div>
    </div>
  </app-confirm-dialog> `,
  styleUrls: ['./revert-playlist-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RevertPlaylistDialogComponent {
  selectedPlaylist!: Playlist;
}
