import { Component } from '@angular/core';
import { ConfirmDialogComponent } from '@desquare/components/common/src/modals/confirm-dialog.component';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
  standalone: true,
  imports: [ConfirmDialogComponent, TranslatePipe],
  selector: 'app-playlist-create-copy-dialog',
  template: `<app-confirm-dialog
    [useDangerButton]="false"
    closeButtonText="PROCEED"
    dismissButtonText="CANCEL"
  >
    <p>{{ 'CREATE_COPY_PROMPT' | translate }}</p>
  </app-confirm-dialog> `,
})
export class PlaylistCreateCopyDialogComponent {}
