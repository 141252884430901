import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Playlist } from '@designage/gql';
import { ConfirmDialogComponent } from '@desquare/components/common/src/modals/confirm-dialog.component';
import { DateProxyPipe } from '@desquare/components/common/src/pipe/pipe/date-proxy.pipe';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
  standalone: true,
  imports: [TranslatePipe, ConfirmDialogComponent, DateProxyPipe],
  selector: 'app-playlist-external-update-dialog',
  template: `<app-confirm-dialog
    [useWarningButton]="true"
    [closeButtonEnabled]="true"
    headerText="PLAYLIST_EXTERNAL_UPDATE"
    closeButtonText="PLAYLIST_EXTERNAL_UPDATE_CONFIRM"
    dismissButtonText="CANCEL"
  >
    <div class="card mb-3">
      <div class="card-body border border-warning rounded">
        <div class="overflow-auto limit">
          <ul class="list-group list-group-flush my-3">
            <li class="list-group-item text-white pt-2 fs-5">
              {{ 'PLAYLIST.PLAYLIST' | translate }}
              <span class="fw-bold ">{{ selectedPlaylist.name }}</span
              ><br />
              {{ 'PLAYLIST_EXTERNAL_UPDATE_UPDATED_BY' | translate }}
              <span class="fw-bold ">{{ updatedBy }}</span>
              <br />
              {{ 'AT' | translate }}
              <span class="fw-bold">
                {{ selectedPlaylist.updatedAt | date: 'medium' }}
              </span>
            </li>
          </ul>
          <p>
            {{ 'PLAYLIST_EXTERNAL_UPDATE_PROMPT_DETAILS' | translate }}
          </p>
        </div>
      </div>
    </div>
  </app-confirm-dialog> `,
  styleUrls: ['./playlist-external-update-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlaylistExternalUpdateDialogComponent {
  selectedPlaylist!: Playlist;
  updatedBy?: string;
}
