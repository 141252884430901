import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input,
  output,
  signal,
} from '@angular/core';
import {
  NgStyle,
  SlicePipe,
  TitleCasePipe,
  LowerCasePipe,
} from '@angular/common';
import { Frequency, PlaylistSchedule, ScheduleRule } from '@designage/gql';
import { IScheduleDays } from '@desquare/interfaces';
import { formatTimeHMToHMS, sortWeekdays } from '@desquare/utils';
import { PlaylistStore } from '@desquare/stores';
import {
  NgbTooltip,
  NgbTimepickerModule,
  NgbDropdownModule,
} from '@ng-bootstrap/ng-bootstrap';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { TimepickerComponent } from '@desquare/components/common/src/timepicker/timepicker.component';
import { DateTime } from 'ts-luxon';
import { parseScheduleRules } from './ruleParser';
import { DateProxyPipe } from '../pipe/pipe/date-proxy.pipe';
@Component({
  selector: 'playlist-schedule-rules',
  standalone: true,
  imports: [
    FormsModule,
    NgStyle,
    NgbTooltip,
    NgbTimepickerModule,
    NgbDropdownModule,
    TranslatePipe,
    TitleCasePipe,
    SlicePipe,
    TimepickerComponent,
    LowerCasePipe,
  ],
  template: `
    <div class="d-flex flex-column">
      <div class=" fs-6 p-0 card-body ">
        <div class="d-flex justify-content-between">
          @if (rulesAsText().length > 0) {
            <div class="d-flex flex-column">
              @for (rule of rulesAsText(); track $index) {
                <div>{{ rule }}</div>
              }
            </div>
          } @else {
            <div class="fs-6">
              {{
                'PLAYLIST.PLAYLIST_SCHEDULE.ADVANCED_SCHEDULE_RULES_NOT_SET'
                  | translate
              }}
            </div>
          }
          <button
            class="btn btn-sm text-white h-0 opacity-0"
            style="margin-top:-0.5rem; margin-bottom:-0.5rem;"
            [class.btn-outline-primary]="editingRules() === false"
            [class.btn-primary]="editingRules() === true"
            [class.opacity-100]="hoveringOverSchedule() || editingRules()"
            (click)="onEditingRulesChange(!editingRules())"
          >
            {{
              'PLAYLIST.PLAYLIST_SCHEDULE.ADVANCED_SCHEDULE_RULES' | translate
            }}
          </button>
        </div>
      </div>
      <div
        class="schedule-rules-container"
        [style.height]="editingRules() ? 'auto' : '0px'"
      >
        <hr class="mt-1 my-2" />

        @for (rule of scheduleRules(); let s = $index; track s) {
          <div class="grid gap-1 align-items-center my-2">
            <div
              class="g-col-11 gap-1 d-flex align-items-center justify-content-start"
            >
              @if (scheduleRules()[s].repeat === 0) {
                <span class="fs-6">{{
                  scheduleRules()[s].duration
                    ? ('PLAYLIST.PLAYLIST_SCHEDULE.PLAY_FOR' | translate)
                    : ('PLAYLIST.PLAYLIST_SCHEDULE.PLAY_BETWEEN' | translate)
                }}</span>
                @if (scheduleRules()[s].duration) {
                  <designage-timepicker
                    [showSecond]="false"
                    [minuteStep]="5"
                    [time]="scheduleRules()[s].duration!"
                    (timeChange)="setDuration(s, $event)"
                    name="scheduleRuleDuration"
                    [container]="'body'"
                    [small]="true"
                  />
                  <span class="fs-6 mx-2">{{
                    'PLAYLIST.PLAYLIST_SCHEDULE.EVERY' | translate | lowercase
                  }}</span>
                  <!-- MARK: Interval Input -->
                  <input
                    type="number"
                    class="fs-6 form-control form-control-dark pe-0"
                    [min]="
                      scheduleRules()[s].frequency === Frequency.Minutely
                        ? 15
                        : 1
                    "
                    [step]="
                      scheduleRules()[s].frequency === Frequency.Minutely
                        ? 15
                        : 1
                    "
                    style="height: 1.6rem; width: 4rem;"
                    [value]="
                      scheduleRules()[s].interval ||
                      (scheduleRules()[s].frequency === Frequency.Minutely
                        ? 15
                        : 1)
                    "
                    (change)="setInterval(s, $event)"
                  />
                  <!-- MARK: Frequency Dropdown -->
                  <div
                    class=" mt-1 "
                    style="height: 1.8rem; width: 7rem;"
                    ngbDropdown
                    container="body"
                  >
                    <input
                      type="button"
                      class="form-control form-control-dark fs-6 form-select dropdown mb-2 w-100 text-truncate"
                      style="height: 1.6rem;"
                      ngbDropdownToggle
                      value="{{
                        'PLAYLIST.PLAYLIST_SCHEDULE.frequency.' +
                          (scheduleRules()[s].frequency || Frequency.Hourly)
                          | translate
                      }}"
                    />
                    <div ngbDropdownMenu class="shadowed-box text-white">
                      <span
                        ngbDropdownItem
                        class="dropdown-items"
                        (click)="setFrequency(s, Frequency.Minutely)"
                        >{{
                          'PLAYLIST.PLAYLIST_SCHEDULE.frequency.MINUTELY'
                            | translate
                        }}</span
                      >
                      <span
                        ngbDropdownItem
                        class="dropdown-items"
                        (click)="setFrequency(s, Frequency.Hourly)"
                        >{{
                          'PLAYLIST.PLAYLIST_SCHEDULE.frequency.HOURLY'
                            | translate
                        }}</span
                      >
                    </div>
                  </div>
                  <span class="fs-6 mx-2">{{
                    'BETWEEN' | translate | lowercase
                  }}</span>
                }
                <designage-timepicker
                  [showSecond]="false"
                  [time]="scheduleRules()[s].startTime"
                  (timeChange)="setScheduleTimeRules('startTime', s, $event)"
                  name="scheduleRuleStartTime"
                  [container]="'body'"
                  [small]="true"
                />
                <span class="fs-6 mx-2">{{
                  'AND' | translate | lowercase
                }}</span>
                <designage-timepicker
                  [showSecond]="false"
                  [time]="scheduleRules()[s].endTime"
                  [minTime]="scheduleRules()[s].startTime"
                  (timeChange)="setScheduleTimeRules('endTime', s, $event)"
                  name="scheduleRuleEndTime"
                  [container]="'body'"
                  [small]="true"
                />
              } @else {
                <span class="fs-6">{{
                  'PLAYLIST.PLAYLIST_SCHEDULE.PLAY' | translate
                }}</span>
                <!-- MARK: Repetition Input -->
                <input
                  type="number"
                  class="fs-6 form-control form-control-dark pe-0"
                  min="1"
                  max="9"
                  style="height: 1.6rem;width: 4rem;"
                  [value]="scheduleRules()[s].repeat || 1"
                  (change)="setRepetitionRules(s, $event)"
                />
                <span class="fs-6 ms-2 text-truncate">
                  {{
                    'PLAYLIST.PLAYLIST_SCHEDULE.TIMES' | translate | lowercase
                  }}
                </span>
                <!-- MARK: Interval Input -->
                <input
                  type="number"
                  class="fs-6 form-control form-control-dark pe-0"
                  [min]="
                    scheduleRules()[s].frequency === Frequency.Minutely ? 15 : 1
                  "
                  [step]="
                    scheduleRules()[s].frequency === Frequency.Minutely ? 15 : 1
                  "
                  style="height: 1.6rem; width: 4rem;"
                  [value]="
                    scheduleRules()[s].interval ||
                    (scheduleRules()[s].frequency === Frequency.Minutely
                      ? 15
                      : 1)
                  "
                  (change)="setInterval(s, $event)"
                />
                <!-- MARK: Frequency Dropdown -->
                <div
                  class=" mt-1 "
                  style="height: 1.8rem; width: 7rem;"
                  ngbDropdown
                  container="body"
                >
                  <input
                    type="button"
                    class="form-control form-control-dark fs-6 form-select dropdown mb-2 w-100 text-truncate"
                    style="height: 1.6rem;"
                    ngbDropdownToggle
                    value="{{
                      'PLAYLIST.PLAYLIST_SCHEDULE.frequency.' +
                        (scheduleRules()[s].frequency || Frequency.Hourly)
                        | translate
                    }}"
                  />
                  <div ngbDropdownMenu class="shadowed-box text-white">
                    <span
                      ngbDropdownItem
                      class="dropdown-items"
                      (click)="setFrequency(s, Frequency.Minutely)"
                      >{{
                        'PLAYLIST.PLAYLIST_SCHEDULE.frequency.MINUTELY'
                          | translate
                      }}</span
                    >
                    <span
                      ngbDropdownItem
                      class="dropdown-items"
                      (click)="setFrequency(s, Frequency.Hourly)"
                      >{{
                        'PLAYLIST.PLAYLIST_SCHEDULE.frequency.HOURLY'
                          | translate
                      }}</span
                    >
                  </div>
                </div>

                <!-- MARK: Start Time -->
                <span class="fs-6 text-truncate">{{
                  'BETWEEN' | translate | lowercase
                }}</span>
                <designage-timepicker
                  [showSecond]="false"
                  [time]="scheduleRules()[s].startTime"
                  (timeChange)="setScheduleTimeRules('startTime', s, $event)"
                  name="scheduleRuleStartTime"
                  [container]="'body'"
                  [small]="true"
                />
                <!-- MARK: End Time -->
                <designage-timepicker
                  [showSecond]="false"
                  [time]="scheduleRules()[s].endTime"
                  (timeChange)="setScheduleTimeRules('endTime', s, $event)"
                  name="scheduleRuleEndTime"
                  [container]="'body'"
                  [small]="true"
                />
              }
              @if (rule.days) {
                <span class="fs-6 me-2">
                  {{ 'ON' | translate | lowercase }}
                </span>
                @for (
                  day of sortWeekdays(rule.days);
                  let i = $index;
                  track day.key
                ) {
                  <label
                    class="day cursor-pointer"
                    [ngStyle]="{
                      'background-color': day.value ? '#178863' : '',
                      border: day.value
                        ? '1px solid #178863'
                        : '1px solid #f1556c',
                    }"
                    (click)="switchDaySchedule(s, day.key)"
                    ngbTooltip="{{ day.key | titlecase }}"
                  >
                    {{ day.key | slice: 0 : 1 | titlecase }}
                  </label>
                }
              }
            </div>
            <div class="g-col-1 g-start-12">
              <span
                ngbTooltip="{{
                  'PLAYLIST.PLAYLIST_SCHEDULE.SCHEDULE_RULE_REMOVE_TT'
                    | translate
                }}"
                (click)="deleteScheduleRule(s)"
                class="badge bg-danger cursor-pointer px-2"
              >
                <i
                  class="mx-1 cursor-pointer fs-6 text-white ri-delete-bin-5-fill"
                ></i>
              </span>
            </div>
          </div>
        }
        <div class="grid gap-1 my-3">
          <div
            class="g-col-4 badge bg-primary cursor-pointer"
            (click)="addStartEndRuleRow()"
            [ngbTooltip]="
              'PLAYLIST.PLAYLIST_SCHEDULE.CONTINUOUS_SCHEDULE_RULES_ADD_TT'
                | translate
            "
          >
            <i
              class="pe-2 cursor-pointer fs-6 text-white ri-add-circle-fill"
            ></i>
            {{
              'PLAYLIST.PLAYLIST_SCHEDULE.CONTINUOUS_SCHEDULE_RULES_ADD'
                | translate
            }}
          </div>
          @if (playlistStore.playlist().type !== 'SCHEDULED') {
            <div
              class="g-col-4 badge bg-primary cursor-pointer"
              (click)="addDurationRuleRow()"
              [ngbTooltip]="
                'PLAYLIST.PLAYLIST_SCHEDULE.DURATION_SCHEDULE_RULES_ADD_TT'
                  | translate
              "
            >
              <i
                class="pe-2 cursor-pointer fs-6 text-white ri-add-circle-fill"
              ></i>
              {{
                'PLAYLIST.PLAYLIST_SCHEDULE.DURATION_SCHEDULE_RULES_ADD'
                  | translate
              }}
            </div>
            <div
              class="g-col-4  badge bg-primary cursor-pointer"
              (click)="addRepeatRuleRow()"
              [ngbTooltip]="
                'PLAYLIST.PLAYLIST_SCHEDULE.PLAY_REPEAT_SCHEDULE_RULES_ADD_TT'
                  | translate
              "
            >
              <i
                class="pe-2 cursor-pointer fs-6 text-white ri-add-circle-fill"
              ></i>
              {{
                'PLAYLIST.PLAYLIST_SCHEDULE.PLAY_REPEAT_SCHEDULE_RULES_ADD'
                  | translate
              }}
            </div>
          } @else {
            <div class="g-col-4 badge bg-secondary">
              {{
                'PLAYLIST.PLAYLIST_SCHEDULE.DURATION_SCHEDULE_RULES_ADD'
                  | translate
              }}
            </div>
            <div class="g-col-4 badge bg-secondary">
              {{
                'PLAYLIST.PLAYLIST_SCHEDULE.PLAY_REPEAT_SCHEDULE_RULES_ADD'
                  | translate
              }}
            </div>
          }
        </div>
      </div>
    </div>
  `,
  styles: `
    .day {
      width: 20px;
      height: 20px;
      line-height: 20px;
      text-align: center;
      font-size: small;
      color: white;
      margin: auto 1px auto 1px;
      border-radius: 0.25rem;
    }
    .schedule-rules-container {
      height: 0;
      overflow: hidden;
      transition: height 0.3s ease-in-out;
    }
    .rules-info {
      transition: opacity 0.3s ease-in-out;
    }
    .rules-button {
      height: 0;
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DateProxyPipe],
})
export class ScheduleRulesComponent {
  playlistSchedule = input.required<PlaylistSchedule>();
  playlistScheduleStatus = input<
    'ACTIVE' | 'INACTIVE' | 'WAITING' | undefined
  >();
  hoveringOverSchedule = input<boolean>(false);
  translate = inject(TranslateService);
  playlistStore = inject(PlaylistStore);
  dateTimePipe = inject(DateProxyPipe);
  editingRules = signal(false);
  editingRulesActive = output<boolean>();

  Frequency = Frequency;

  scheduleRules = computed(
    () => this.playlistStore.playlist().schedule?.rules || [],
  );

  rulesAsText = computed(() =>
    parseScheduleRules(
      this.playlistSchedule(),
      this.translate,
      this.dateTimePipe,
    ),
  );

  onEditingRulesChange(active: boolean) {
    this.editingRules.set(active);
    this.editingRulesActive.emit(this.editingRules());
  }

  sortWeekdays = (weekdays: IScheduleDays) => {
    return sortWeekdays(weekdays);
  };
  switchDaySchedule(scheduleIndex: number, day: string) {
    const updatedRules = this.scheduleRules().map((rule, i) =>
      i === scheduleIndex
        ? {
            ...rule,
            days: {
              ...rule.days,
              [day]: !rule.days[day as keyof IScheduleDays],
            },
          }
        : rule,
    );
    this.playlistStore.setScheduleRules(updatedRules);
  }

  setScheduleTimeRules(
    operation: 'startTime' | 'endTime',
    scheduleIndex: number,
    value: string,
  ) {
    if (operation === 'startTime' || operation === 'endTime') {
      value = formatTimeHMToHMS(value);
    }
    console.log(operation, scheduleIndex, value);
    this.playlistStore.setScheduleRules(
      this.scheduleRules().map((rule, i) =>
        i === scheduleIndex ? { ...rule, [operation]: value } : rule,
      ),
    );
  }

  setRepetitionRules(scheduleIndex: number, repeat: number | Event) {
    if (typeof repeat !== 'number') {
      repeat = parseInt((repeat.target as HTMLInputElement).value);
    }
    this.playlistStore.setScheduleRules(
      this.scheduleRules().map((rule, i) =>
        i === scheduleIndex ? { ...rule, repeat } : rule,
      ),
    );
  }

  setDuration(scheduleIndex: number, duration: string | Event) {
    if (typeof duration !== 'string') {
      duration = (duration.target as HTMLInputElement).value;
    }
    if (duration.split(':').length === 2) {
      duration = DateTime.fromFormat(duration, 'HH:mm').toFormat('HH:mm:ss');
    }
    console.log('duration', duration);
    this.playlistStore.setScheduleRules(
      this.scheduleRules().map((rule, i) =>
        i === scheduleIndex ? { ...rule, duration } : rule,
      ),
    );
  }

  setInterval(scheduleIndex: number, value: number | Event) {
    if (typeof value !== 'number') {
      value = parseInt((value.target as HTMLInputElement).value);
    }
    this.playlistStore.setScheduleRules(
      this.scheduleRules().map((rule, i) =>
        i === scheduleIndex ? { ...rule, interval: value } : rule,
      ),
    );
  }

  setFrequency(scheduleIndex: number, frequency: Frequency) {
    console.log(frequency);
    if (frequency === Frequency.Minutely) {
      this.playlistStore.setScheduleRules(
        this.scheduleRules().map((rule, i) =>
          i === scheduleIndex ? { ...rule, frequency, interval: 15 } : rule,
        ),
      );
    }
    if (frequency === Frequency.Hourly) {
      this.playlistStore.setScheduleRules(
        this.scheduleRules().map((rule, i) =>
          i === scheduleIndex ? { ...rule, frequency, interval: 1 } : rule,
        ),
      );
    }
  }

  deleteScheduleRule(scheduleIndex: number) {
    this.playlistStore.setScheduleRules(
      this.scheduleRules().filter((_, i) => i !== scheduleIndex),
    );
  }

  addStartEndRuleRow() {
    const scheduleRuleTemplate = (): ScheduleRule => {
      let startTime = '12:00:00';
      if (this.scheduleRules.length > 0) {
        const lastRule = this.scheduleRules()[this.scheduleRules().length - 1];
        startTime = DateTime.fromFormat(lastRule.endTime, 'HH:mm:ss')
          .plus({ hours: 1 })
          .toFormat('HH:mm:ss');
      }
      const endTime = DateTime.fromFormat(startTime, 'HH:mm:ss')
        .plus({ minutes: 15 })
        .toFormat('HH:mm:ss');
      return {
        days: {
          monday: true,
          tuesday: true,
          wednesday: true,
          thursday: true,
          friday: true,
          saturday: true,
          sunday: true,
        },
        startTime: startTime,
        endTime: endTime,
        frequency: null,
        interval: null,
        repeat: 0,
      };
    };
    this.playlistStore.setScheduleRules([
      ...this.scheduleRules(),
      scheduleRuleTemplate(),
    ]);
  }
  addDurationRuleRow() {
    const scheduleRuleTemplate = (): ScheduleRule => {
      let startTime = '12:00:00';
      if (this.scheduleRules.length > 0) {
        const lastRule = this.scheduleRules()[this.scheduleRules().length - 1];
        startTime = DateTime.fromFormat(lastRule.endTime, 'HH:mm:ss')
          .plus({ hours: 1 })
          .toFormat('HH:mm:ss');
      }
      const endTime = DateTime.fromFormat(startTime, 'HH:mm:ss')
        .plus({ hours: 1 })
        .toFormat('HH:mm:ss');
      const duration = '00:30:00';

      return {
        days: {
          monday: true,
          tuesday: true,
          wednesday: true,
          thursday: true,
          friday: true,
          saturday: true,
          sunday: true,
        },
        startTime,
        endTime,
        frequency: Frequency.Hourly,
        duration,
        interval: 1,
        repeat: 0,
      };
    };
    this.playlistStore.setScheduleRules([
      ...this.scheduleRules(),
      scheduleRuleTemplate(),
    ]);
  }
  addRepeatRuleRow() {
    const scheduleRuleTemplate = (): ScheduleRule => {
      let startTime = '12:00:00';
      if (this.scheduleRules().length > 0) {
        const lastRule = this.scheduleRules()[this.scheduleRules().length - 1];
        startTime = DateTime.fromFormat(lastRule.endTime, 'HH:mm:ss')
          .plus({ hours: 1 })
          .toFormat('HH:mm:ss');
      }
      const endTime = DateTime.fromFormat(startTime, 'HH:mm:ss')
        .plus({ hours: 1 })
        .toFormat('HH:mm:ss');
      return {
        days: {
          monday: true,
          tuesday: true,
          wednesday: true,
          thursday: true,
          friday: true,
          saturday: true,
          sunday: true,
        },
        frequency: Frequency.Hourly,
        interval: 1,
        startTime: startTime,
        endTime: endTime,
        repeat: 1,
      };
    };
    this.playlistStore.setScheduleRules([
      ...this.scheduleRules(),
      scheduleRuleTemplate(),
    ]);
  }
  setPlayBetween(scheduleIndex: number, value: boolean) {
    this.playlistStore.setScheduleRules(
      this.scheduleRules().map((rule, i) =>
        i === scheduleIndex ? { ...rule, repeat: value ? 0 : 1 } : rule,
      ),
    );
  }
}
