import { DragDropModule } from '@angular/cdk/drag-drop';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import {
  ChannelPlaylist,
  ChannelsForChannelListFragment,
  Maybe,
} from '@designage/gql';
import { LayoutExplorerComponent } from '@desquare/components/common/src/layout-explorer/layout-explorer.component';
import {
  NgbDropdownModule,
  NgbTooltipModule,
  NgbPopoverModule,
} from '@ng-bootstrap/ng-bootstrap';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    TranslatePipe,
    MatTableModule,
    MatSortModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatCheckboxModule,
    DragDropModule,
    NgbDropdownModule,
    NgbTooltipModule,
    NgbPopoverModule,
    LayoutExplorerComponent,
  ],
  selector: 'app-channel-region-selection',
  templateUrl: './channel-region-selection.component.html',
  styleUrls: ['./channel-region-selection.component.scss'],
})
export class ChannelRegionSelectionComponent implements OnInit {
  @Input() selectedChannels: ChannelPlaylist[] = [];
  @Output() selectedChannelsChange = new EventEmitter<ChannelPlaylist[]>();

  dataSource = new MatTableDataSource<any>([]);

  showFilter = true;

  @ViewChild(MatSort) set matSort(sort: MatSort) {
    // this needs to be a setter to ensure sort is added AFTER it is defined in the template, otherwise it won't work
    this.dataSource.sort = sort;
  }
  loading = false;

  columnDefs = ['select', 'name', 'layout', 'region'];

  filterValue: string = '';

  _channels: Maybe<ChannelsForChannelListFragment>[] = [];
  @Input('channels') set data(value: any[]) {
    this.dataSource.data = value;
    this._channels = [...value];
    /*this.dataSource.sortingDataAccessor = (obj, property) =>
      this.getFieldValue(obj, property);*/
  }
  get channels() {
    return this._channels;
  }

  get visibleChannels() {
    const search = this.filterValue.toLocaleLowerCase();
    return this.channels.filter((x) => {
      x?.id.toLowerCase().includes(search) ||
        x?.name.toLowerCase().includes(search) ||
        x?.description?.toLowerCase().includes(search);
    });
  }

  constructor() {}

  ngOnInit(): void {
    setTimeout(() => {
      console.log('channels', this.channels);
    }, 1000);
  }

  isSelected(channelId: string) {
    try {
      return (
        this.selectedChannels.findIndex((x) => x.channelId === channelId) >= 0
      );
    } catch {
      return false;
    }
  }

  /** toggle selection */
  selectHandler(channelId: string) {
    const idx = this.selectedChannels.findIndex(
      (x) => x.channelId === channelId,
    );
    if (idx >= 0) {
      this.selectedChannels.splice(idx, 1);
    } else {
      this.addToSelection(channelId);
    }
    this.selectedChannelsChange.emit(this.selectedChannels);
  }

  /** if channel is not already selected it adds to selection */
  addToSelection(channelId: string) {
    if (this.isSelected(channelId)) {
      return;
    }
    this.selectedChannels.push({
      playlistId: '',
      channelId,
      region: '',
    });
  }

  /** return selected region for a channel */
  getRegion(channelId: string) {
    const idx = this.selectedChannels.findIndex(
      (x) => x.channelId === channelId,
    );
    if (idx >= 0) {
      return this.selectedChannels[idx].region || '';
    }
    return '';
  }

  setRegion(channelId: string, e: Event | string) {
    const region =
      typeof e === 'string' ? e : (e.target as HTMLInputElement).value;
    this.addToSelection(channelId);
    const idx = this.selectedChannels.findIndex(
      (x) => x.channelId === channelId,
    );
    if (idx >= 0) {
      console.log('setRegion', region);

      this.selectedChannels[idx].region = region;

      this.selectedChannelsChange.emit(this.selectedChannels);
    }
  }

  /** get available regions for a channels */
  getChannelRegions(channelId: string) {
    const channel = this.channels.find((x) => x?.id === channelId);
    const regions = channel?.layout?.source?.regionBlocks?.map(
      (x) => x?.regionName,
    );

    return regions;
  }

  /** is currently selected region compatible with channel layout? */
  mustUpdateRegion(channelId: string) {
    const currentRegion = this.getRegion(channelId);
    if (currentRegion !== '') {
      const channelRegions = this.getChannelRegions(channelId);
      return !channelRegions?.includes(currentRegion);
    }
    return false;
  }

  applyFilter(event: KeyboardEvent) {
    if (event.code === 'Escape') {
      return this.clearFilter();
    }

    this.filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = this.filterValue.trim().toLowerCase();
  }
  clearFilter() {
    this.dataSource.filter = '';
    this.filterValue = '';
  }
}
