import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  inject,
  computed,
  effect,
  ChangeDetectionStrategy,
  viewChild,
  untracked,
  signal,
  output,
  input,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  Maybe,
  PlaylistStatus,
  DuplicatePlaylistsGQL,
  ChannelPlaylist,
  ChannelsForChannelListFragment,
  PlaylistType,
} from '@designage/gql';
import {
  EncryptionService,
  ToasterService,
  ChannelService,
  UiMode,
  CurrentUserService,
  ResponsiveUiService,
  SessionService,
  PlaylistService,
  SlidePanelService,
} from '@desquare/services';
import { AngularSplitModule } from 'angular-split';
import { cloneDeep } from 'lodash';
import { AsyncPipe, DatePipe, NgTemplateOutlet } from '@angular/common';
import { Router } from '@angular/router';
import {
  NgbDropdownModule,
  NgbModal,
  NgbNavModule,
  NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';
import { PlaylistPublishingDialogComponent } from '@designage/app/playlist/playlist-publishing-dialog/playlist-publishing-dialog.component';
import { PlaylistRevertVersionDialogComponent } from '@designage/app/playlist/playlist-revert-version-dialog/playlist-revert-version-dialog.component';
import { PlaylistCreateCopyDialogComponent } from '@designage/app/playlist/playlist-create-copy-dialog/playlist-create-copy-dialog.component';
import { ContentPreviewComponent } from '@designage/app/shared/content-preview/content-preview.component';
import { Observable } from 'rxjs';
import _ from 'lodash';
import { TranslatePipe } from '@ngx-translate/core';
import { ChannelRegionSelectionComponent } from '@designage/app/shared/channel-region-selection/channel-region-selection.component';
import { PlaylistVersionComponent } from '@designage/app/playlist/playlist-version/playlist-version.component';
import { DateProxyPipe } from '@desquare/components/common/src/pipe/pipe/date-proxy.pipe';
import { PlaylistPreviewStore, PlaylistStore } from '@desquare/stores';
import { SequenceListComponent } from '@designage/app/playlist-editor/sequence-list/sequence-list.component';
import { MediaListComponent } from '../../media/media-list/media-list.component';
import { DesValidationMessageComponent } from '@desquare/ui-components/des-validation-message.component';
import { DeletePlaylistDialogComponent } from '@designage/app/playlist/delete-playlist-dialog/delete-playlist-dialog.component';
import { RevertPlaylistDialogComponent } from '@designage/app/playlist/revert-playlist-dialog/revert-playlist-dialog.component';
import { DesButtonComponent } from '@desquare/ui-components/des-button.component';
import { PlaylistExternalUpdateDialogComponent } from '@designage/app/playlist/playlist-external-update-dialog/playlist-external-update-dialog.component';
import { PlaylistSchedularComponent } from '@desquare/components/common/src/playlist-schedular/playlistSchedular.component';

@Component({
  standalone: true,
  imports: [
    FormsModule,
    NgTemplateOutlet,
    ReactiveFormsModule,
    TranslatePipe,
    NgbNavModule,
    AngularSplitModule,
    SequenceListComponent,
    ContentPreviewComponent,
    ChannelRegionSelectionComponent,
    PlaylistVersionComponent,
    DesValidationMessageComponent,
    DateProxyPipe,
    NgbDropdownModule,
    AsyncPipe,
    MediaListComponent,
    NgbTooltipModule,
    DesButtonComponent,
    PlaylistSchedularComponent,
  ],
  selector: 'designage-playlist-form-new',
  templateUrl: './playlist-form-new.component.html',
  styleUrls: ['./playlist-form-new.component.scss'],
  providers: [DatePipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlaylistFormNewComponent implements OnInit {
  private playlistStore = inject(PlaylistStore);
  private playlistPreviewStore = inject(PlaylistPreviewStore);
  private playlistService = inject(PlaylistService);
  private datePipe = inject(DatePipe);
  private session = inject(SessionService);
  private encryptionService = inject(EncryptionService);
  private modalService = inject(NgbModal);
  private duplicatePlaylistsGQL = inject(DuplicatePlaylistsGQL);
  private router = inject(Router);
  private toasterService = inject(ToasterService);
  private channelService = inject(ChannelService);
  public currentUserService = inject(CurrentUserService);
  public responsiveUiService = inject(ResponsiveUiService);
  private slidePanelService = inject(SlidePanelService);

  createPlaylist = input<boolean>(false);
  invokedFrom = input<string>();

  playlistPublished = output<void>();

  playlist = this.playlistStore.playlist;

  assetIds = computed(() => {
    const assets = this.playlist.assets() || [];
    return assets.map((x) => x.id);
  });
  saving = signal<boolean>(false);
  deleting = signal<boolean>(false);
  publishing = signal<boolean>(false);
  loading = computed(() => this.playlistStore.loading());
  loaderMessage = computed(() => this.playlistStore.loaderMessage());
  PlaylistType = PlaylistType;

  playlistType = computed(
    () => this.playlistStore.playlist.type() ?? PlaylistType.Scheduled,
  );
  isInteractive = computed(
    () => this.playlistType() === PlaylistType.Interactive,
  );

  /** enum of statuses */
  playlistStatus = PlaylistStatus;

  changeDetected = this.playlistStore.isDirty;

  selectedChannels = computed(() =>
    this.playlist().channelRegions ? this.playlist().channelRegions : [],
  );
  publishedAssets = computed(
    () => this.playlist().activePlaylist?.assets || [],
  );

  validFields = computed(() => this.playlistStore.isValid());

  playlistUpdatedExternally = computed(() => {
    if (this.playlistStore.isChanged()) {
      this.openExternalUpdateDialog();
    }
    return false;
  });

  @ViewChild('playlistNameInput') playlistNameInput!: ElementRef;
  preview = viewChild<ContentPreviewComponent>('preview');

  isEditingName = signal<boolean>(false);
  updatedAt!: Maybe<string>;

  simulateDateTime = computed(() => {
    return this.preview()?.simulateDateTime() || null;
  });

  initialized = false;
  profileId!: Maybe<string>;
  comment!: Maybe<string>;

  channelFilter!: string;

  invalidDateRange!: boolean;

  activeChannels: string[] = [];
  simpleUiActive = false;
  uiMode$!: Observable<UiMode>;
  playlistStartDate!: string;
  playlistEndDate!: string;
  activeIdSettingsPanel = signal<number>(1);

  channels: Maybe<ChannelsForChannelListFragment>[] = [];

  // TODO: enable this with a custom feature connected to profile
  advancedScheduleEnabled = false;

  constructor() {
    effect(() => {
      this.playlistStore.isChanged();

      if (
        this.session.profileId() &&
        this.playlistStore.isDirty() &&
        !this.playlistStore.loading()
      ) {
        const playlistValue = {
          ...cloneDeep(this.playlist()),
        };
        this.playlistService.savePendingPlaylist(playlistValue);
      }
    });
    effect(() => {
      const activeIdSettingsPanel = this.activeIdSettingsPanel();
      untracked(() => {
        if (activeIdSettingsPanel !== 2) {
          this.playlistPreviewStore.setContentStatus(false);
        } else {
          this.playlistPreviewStore.setContentStatus(true);
        }
      });
    });
  }

  ngOnInit() {
    this.initVariables();
    this.initSubscriptions();
  }

  initVariables() {
    this.profileId = this.session.profileId() || '';
    this.invalidDateRange = false;
    if (this.createPlaylist()) {
      // Set focus to playlist name input when creating a new playlist
      this.playlistStore.createNew();
      this.playlistStore.addSequence();
      setTimeout(() => {
        this.editPlaylistNameInput();
      });
    }

    this.responsiveUiService.isMobileDevice()
      ? this.activeIdSettingsPanel.set(1)
      : this.activeIdSettingsPanel.set(2);
    this.isInteractive() || this.createPlaylist() || this.hasNoContent()
      ? this.activeIdSettingsPanel.set(3)
      : this.activeIdSettingsPanel.set(2);
  }

  /**
   * Checks if the playlist has no content in any of its assets
   * @returns true if all assets have empty content arrays, false otherwise
   */
  hasNoContent(): boolean {
    const assets = this.playlist.assets();

    // If there are no assets at all, return true (no content)
    if (!assets || assets.length === 0) {
      return true;
    }

    // Check each asset to see if it has any content
    for (const asset of assets) {
      // If the asset has content and the content array has items, return false (has content)
      if (asset.content && asset.content.length > 0) {
        return false;
      }
    }

    // If we've checked all assets and found no content, return true
    return true;
  }

  initSubscriptions() {
    this.uiMode$ = this.currentUserService.getUiMode();

    this.initChannels();
  }

  async initChannels() {
    this.channels = [];
    this.channels = await this.channelService.getCurrentProfileChannels();
  }

  setSelectedChannels(channelRegions: ChannelPlaylist[]) {
    this.playlistStore.setChannelRegions(channelRegions);
  }

  getNewIsoDate() {
    const d = new Date().toISOString();
    return d.substring(0, d.indexOf('T'));
  }

  updatePlaylistName() {
    this.playlistStore.setPlaylistName(
      this.playlistNameInput.nativeElement.value,
    );
    this.isEditingName.set(false);
  }

  transformDate(dateString: string) {
    return this.datePipe.transform(dateString, 'short');
  }

  async publish() {
    this.publishing.set(true);
    let validForm = this.playlistStore.isValid();

    if (!validForm) {
      console.log('invalid form');
      return;
    }

    const channels = await this.channelService.getCurrentProfileChannels();
    const selectedChannelIds = (this.playlist().channelRegions || []).map(
      (x) => x.channelId,
    );

    const modal = this.modalService.open(PlaylistPublishingDialogComponent, {
      backdrop: 'static',
    });
    modal.componentInstance.playlistName = this.playlist.name();
    modal.componentInstance.channels = channels
      .filter((x) => !!x && selectedChannelIds?.includes(x.id))
      .map((x) => x?.name)
      .filter((x) => !!x);
    modal.componentInstance.activeChannels = this.activeChannels;
    modal.result
      .then(async (value) => {
        if (value && 'comment' in value) {
          const { comment } = value;

          return await this.playlistStore.publish(comment);
        }
        await this.playlistStore.publish('');
        this.playlistPublished.emit();
      })
      .finally(() => {
        this.publishing.set(false);
      });
  }

  async save() {
    this.saving.set(true);
    await this.playlistStore
      .save()
      .then(() => {
        this.toasterService.success(
          'PLAYLIST.PLAYLIST_SAVED_SUCCESS',
          undefined,
          {
            playlistName: this.playlist().name,
          },
        );
      })
      .catch(() => {
        this.toasterService.error('PLAYLIST.PLAYLIST_SAVING_FAILED');
      });
    this.saving.set(false);
  }

  // When creating a new playlist, we need to save it and then navigate to the manage page
  async saveNew() {
    this.saving.set(true);

    await this.playlistStore.save().then(() => {
      this.slidePanelService.setPanelComponentId(this.playlist.id());
      const id = this.encryptionService.encrypt(this.playlist.id());
      if (this.currentUserService.canManagePlaylist && id) {
        // open route
        this.router.navigate(['playlists/manage2', id]);
      }
      this.saving.set(false);
    });
  }

  async revert() {
    this.playlistStore.getPlaylistFromApi(this.playlist.id());
  }

  openDeletePlaylistDialog() {
    if (this.playlist) {
      const modal = this.modalService.open(DeletePlaylistDialogComponent, {
        backdrop: 'static',
      });
      const componentInstance =
        modal.componentInstance as DeletePlaylistDialogComponent;
      componentInstance.selectedPlaylists = [this.playlist()];
      componentInstance.activeChannelsName = this.playlist().channels?.map(
        (x) => x.name,
      );

      modal.result
        .then(async () => {
          this.deleting.set(true);
          await this.playlistService.deletePlaylists([this.playlist.id()]);
          this.deleting.set(false);
          this.toasterService.success('DELETE_PLAYLIST_SUCCESS');
          this.router.navigate(['/playlists']);
        })
        .finally(() => {
          this.deleting.set(false);
        });
    }
  }

  openRevertPlaylistDialog() {
    if (this.playlist) {
      const modal = this.modalService.open(RevertPlaylistDialogComponent, {
        backdrop: 'static',
      });
      const componentInstance =
        modal.componentInstance as RevertPlaylistDialogComponent;
      componentInstance.selectedPlaylist = this.playlist();

      modal.result
        .then(async () => {
          this.saving.set(true);
          await this.playlistStore.getPlaylistFromApi(this.playlist.id());
          this.toasterService.success('REVERTED_PLAYLIST_SUCCESS');
          this.saving.set(false);
        })
        .finally(() => {
          this.saving.set(false);
        });
    }
  }
  openExternalUpdateDialog() {
    if (this.playlist) {
      const modal = this.modalService.open(
        PlaylistExternalUpdateDialogComponent,
        {
          backdrop: 'static',
        },
      );
      const componentInstance =
        modal.componentInstance as PlaylistExternalUpdateDialogComponent;
      componentInstance.selectedPlaylist = this.playlist();
      componentInstance.updatedBy = this.playlist().user?.displayName as string;

      modal.result
        .then(async () => {
          this.saving.set(true);
          await this.playlistStore.getPlaylistFromApi(this.playlist.id());
          this.toasterService.success('REVERTED_PLAYLIST_SUCCESS');
          this.saving.set(false);
        })
        .finally(() => {
          this.saving.set(false);
        });
    }
  }

  editPlaylistNameInput() {
    this.isEditingName.set(true);
    setTimeout(() => {
      this.playlistNameInput.nativeElement.value = this.playlist().name;
      this.playlistNameInput.nativeElement.select();
    });
  }

  revertToVersion(id: string) {
    const modal = this.modalService.open(PlaylistRevertVersionDialogComponent, {
      backdrop: 'static',
    });
    modal.result
      .then(() => {
        this.revertPlaylist(id);
      })
      .catch(() => {});
  }

  async revertPlaylist(id: string) {
    this.playlistStore.revertTo(id);
  }

  createCopy(id: string) {
    const modal = this.modalService.open(PlaylistCreateCopyDialogComponent, {
      backdrop: 'static',
    });
    modal.result
      .then(() => {
        this.duplicatePlaylist(id);
      })
      .catch(() => {});
  }

  duplicatePlaylist(id: string) {
    this.duplicatePlaylistsGQL.mutate({ ids: [id] }).subscribe(({ data }) => {
      if (
        data?.duplicatePlaylists.isSuccessful &&
        data.duplicatePlaylists.playlistIds?.length
      ) {
        const playlistId = this.encryptionService.encrypt(
          data.duplicatePlaylists.playlistIds[0] || '',
        );
        if (playlistId) {
          this.toasterService.success(
            'Successfully created a copy of the version.',
          );
          this.router.navigate(['/playlist/manage', playlistId]);
        }
      } else {
        this.toasterService.error('Failed to create a copy of the version.');
      }
    });
  }
  setPlaylistType(type: PlaylistType) {
    this.playlistStore.setPlaylistType(type);
    console.log('setPlaylistType', type);
  }
}
