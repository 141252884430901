import { Frequency, PlaylistSchedule } from '@designage/gql';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'ts-luxon';
import { DateProxyPipe } from '../pipe/pipe/date-proxy.pipe';

const getDaysText = (days: string[]): string => {
  if (days.length === 7) return 'every day';
  if (days.length === 1) return `on ${days[0].toLowerCase()}s`;

  const lastDay = days[days.length - 1].toLowerCase();
  const otherDays = days
    .slice(0, -1)
    .map((d) => d.toLowerCase())
    .join(', ');
  return `on ${otherDays} and ${lastDay}s`;
};

function getDurationText(
  duration: string,
  translate: TranslateService,
): string {
  // Parse the duration string (HH:mm:ss)
  const [hours, minutes] = duration.split(':').map(Number);

  // Handle plural forms
  const hoursText =
    hours === 1 ? translate.instant('HOUR') : translate.instant('HOURS');
  const minutesText =
    minutes === 1 ? translate.instant('MINUTE') : translate.instant('MINUTES');

  // Return appropriate format based on hours
  if (hours > 0) {
    return `${hours} ${hoursText} ${translate.instant('AND')} ${minutes} ${minutesText}`;
  }

  return `${minutes} ${minutesText}`;
}

const getScheduleDetails = (schedule: PlaylistSchedule) => {
  const startDateTime = schedule.startDate || null;
  const endDateTime = schedule.endDate || null;

  const startFullDay =
    DateTime.fromISO(startDateTime, { setZone: true })
      .startOf('day')
      .toString() === startDateTime;
  const endFullDay =
    DateTime.fromISO(endDateTime, { setZone: true }).endOf('day').toString() ===
    endDateTime;
  return { startDateTime, endDateTime, startFullDay, endFullDay };
};

export const parseGeneralScheduleText = (
  schedule: PlaylistSchedule,
  translate: TranslateService,
  dateTimePipe: DateProxyPipe,
): string | null => {
  const formatForDatePipe = (date: string, pipe: string): string => {
    return dateTimePipe.transform(date, pipe, undefined, 'UTC') || '';
  };
  const { startDateTime, endDateTime, startFullDay, endFullDay } =
    getScheduleDetails(schedule);

  // Only start date exists
  if (startDateTime && !endDateTime) {
    if (startFullDay) {
      return translate.instant(
        'PLAYLIST.PLAYLIST_SCHEDULE.general.START_DATE',
        {
          date: formatForDatePipe(startDateTime, 'shortDate'),
        },
      );
    }
    return translate.instant(
      'PLAYLIST.PLAYLIST_SCHEDULE.general.START_DATE_TIME',
      {
        date: formatForDatePipe(startDateTime, 'shortDate'),
        startTime: formatForDatePipe(startDateTime, 'shortTime'),
      },
    );
  }

  // Only end date exists
  if (!startDateTime && endDateTime) {
    if (endFullDay) {
      return translate.instant('PLAYLIST.PLAYLIST_SCHEDULE.general.END_DATE', {
        date: formatForDatePipe(endDateTime, 'shortDate'),
      });
    }
    return translate.instant(
      'PLAYLIST.PLAYLIST_SCHEDULE.general.END_DATE_TIME',
      {
        date: formatForDatePipe(endDateTime, 'shortDate'),
        endTime: formatForDatePipe(endDateTime, 'shortTime'),
      },
    );
  }

  // Both dates exist - handle same day vs date range
  if (startDateTime && endDateTime) {
    // Check if start and end date are the same day
    if (
      DateTime.fromISO(startDateTime, { setZone: true }).toFormat(
        'yyyy-MM-dd',
      ) ===
      DateTime.fromISO(endDateTime, { setZone: true }).toFormat('yyyy-MM-dd')
    ) {
      // Full day cases
      if (startFullDay && endFullDay) {
        console.log('startFullDay', startFullDay);
        console.log('endFullDay', endFullDay);
        return translate.instant(
          'PLAYLIST.PLAYLIST_SCHEDULE.general.sameDay.FULL_DAY',
          {
            date: formatForDatePipe(startDateTime, 'shortDate'),
          },
        );
      }

      if (startFullDay) {
        return translate.instant(
          'PLAYLIST.PLAYLIST_SCHEDULE.general.sameDay.MIDNIGHT_TO_END_TIME',
          {
            date: formatForDatePipe(startDateTime, 'shortDate'),
            endTime: formatForDatePipe(endDateTime, 'shortTime'),
          },
        );
      }

      if (endFullDay) {
        return translate.instant(
          'PLAYLIST.PLAYLIST_SCHEDULE.general.sameDay.START_TIME_TO_MIDNIGHT',
          {
            date: formatForDatePipe(startDateTime, 'shortDate'),
            startTime: formatForDatePipe(startDateTime, 'shortTime'),
          },
        );
      }

      // Regular time range on same day

      return translate.instant(
        'PLAYLIST.PLAYLIST_SCHEDULE.general.sameDay.START_TIME_TO_END_TIME',
        {
          date: formatForDatePipe(startDateTime, 'shortDate'),
          startTime: formatForDatePipe(startDateTime, 'shortTime'),
          endTime: formatForDatePipe(endDateTime, 'shortTime'),
        },
      );
    }
    // Date range
    if (startFullDay && endFullDay) {
      console.log('startFullDay', startFullDay);
      console.log('endFullDay', endFullDay);
      return translate.instant(
        'PLAYLIST.PLAYLIST_SCHEDULE.general.dateRange.DATE_RANGE_FULL_DAY',
        {
          startDate: formatForDatePipe(startDateTime, 'shortDate'),
          endDate: formatForDatePipe(endDateTime, 'shortDate'),
        },
      );
    }

    if (startFullDay) {
      return translate.instant(
        'PLAYLIST.PLAYLIST_SCHEDULE.general.dateRange.DATE_RANGE_MIDNIGHT_TO_END_TIME',
        {
          startDate: formatForDatePipe(startDateTime, 'shortDate'),
          endDate: formatForDatePipe(endDateTime, 'shortDate'),
          endTime: formatForDatePipe(endDateTime, 'shortTime'),
        },
      );
    }

    if (endFullDay) {
      return translate.instant(
        'PLAYLIST.PLAYLIST_SCHEDULE.general.dateRange.DATE_RANGE_START_TIME_TO_MIDNIGHT',
        {
          startDate: formatForDatePipe(startDateTime, 'shortDate'),
          endDate: formatForDatePipe(endDateTime, 'shortDate'),
          startTime: formatForDatePipe(startDateTime, 'shortTime'),
        },
      );
    }
    return translate.instant(
      'PLAYLIST.PLAYLIST_SCHEDULE.general.dateRange.DATE_RANGE',
      {
        startDate: formatForDatePipe(startDateTime, 'shortDate'),
        endDate: formatForDatePipe(endDateTime, 'shortDate'),
        startTime: formatForDatePipe(startDateTime, 'shortTime'),
        endTime: formatForDatePipe(endDateTime, 'shortTime'),
      },
    );
  }
  // No dates set
  return null;
};

export const parseScheduleRules = (
  schedule: PlaylistSchedule,
  translate: TranslateService,
  dateTimePipe: DateProxyPipe,
): string[] => {
  // Parse dates and times
  const formatForDatePipe = (date: string, pipe: string): string => {
    const dateTime = DateTime.fromFormat(date, 'HH:mm:ss', { zone: 'UTC' });
    return (
      dateTimePipe.transform(dateTime.toISO(), pipe, undefined, 'UTC') || ''
    );
  };

  // Process rules within general schedule constraints
  const scheduleRules = schedule.rules?.map((rule) => {
    const days = Object.entries(rule.days)
      .filter(([key, isEnabled]) => key !== '__typename' && isEnabled)
      .map(([day]) => day.toUpperCase());

    const frequencyMinutely = translate.instant(
      'PLAYLIST.PLAYLIST_SCHEDULE.frequency.MINUTELY',
    );
    const frequencyHourly = translate.instant(
      'PLAYLIST.PLAYLIST_SCHEDULE.frequency.HOURLY',
    );

    // Create base rule text
    let ruleText = '';

    // Handle repeat >= 2 first
    if (rule.repeat && rule.repeat >= 2) {
      if (days.length === 7) {
        ruleText = translate.instant(
          'PLAYLIST.PLAYLIST_SCHEDULE.rules.REPEAT_2_OR_MORE',
          {
            repeat: rule.repeat,
            frequency:
              rule.frequency === Frequency.Minutely
                ? frequencyMinutely
                : frequencyHourly,
            interval: rule.interval,
            startTime: formatForDatePipe(rule.startTime, 'shortTime'),
            endTime: formatForDatePipe(rule.endTime, 'shortTime'),
          },
        );
      } else {
        ruleText = translate.instant(
          'PLAYLIST.PLAYLIST_SCHEDULE.rules.REPEAT_2_OR_MORE_ON_DAYS',
          {
            repeat: rule.repeat,
            frequency:
              rule.frequency === Frequency.Minutely
                ? frequencyMinutely
                : frequencyHourly,
            interval: rule.interval,
            startTime: formatForDatePipe(rule.startTime, 'shortTime'),
            endTime: formatForDatePipe(rule.endTime, 'shortTime'),
            days: getDaysText(days),
          },
        );
      }
    } else {
      // Handle other cases with switch
      switch (rule.repeat) {
        case 0:
          if (days.length === 7) {
            if (rule.duration) {
              ruleText = translate.instant(
                'PLAYLIST.PLAYLIST_SCHEDULE.rules.REPEAT_0_DURATION',
                {
                  duration: getDurationText(rule.duration, translate),
                  startTime: formatForDatePipe(rule.startTime, 'shortTime'),
                  endTime: formatForDatePipe(rule.endTime, 'shortTime'),
                  days: getDaysText(days),
                  interval: rule.interval,
                  frequency:
                    rule.frequency === Frequency.Minutely
                      ? frequencyMinutely
                      : frequencyHourly,
                },
              );
            } else {
              ruleText = translate.instant(
                'PLAYLIST.PLAYLIST_SCHEDULE.rules.REPEAT_0',
                {
                  startTime: formatForDatePipe(rule.startTime, 'shortTime'),
                  endTime: formatForDatePipe(rule.endTime, 'shortTime'),
                },
              );
            }
          } else {
            if (rule.duration) {
              ruleText = translate.instant(
                'PLAYLIST.PLAYLIST_SCHEDULE.rules.REPEAT_0_DURATION_ON_DAYS',
                {
                  duration: getDurationText(rule.duration, translate),
                  startTime: formatForDatePipe(rule.startTime, 'shortTime'),
                  endTime: formatForDatePipe(rule.endTime, 'shortTime'),
                  days: getDaysText(days),
                  interval: rule.interval,
                  frequency:
                    rule.frequency === Frequency.Minutely
                      ? frequencyMinutely
                      : frequencyHourly,
                },
              );
            } else {
              ruleText = translate.instant(
                'PLAYLIST.PLAYLIST_SCHEDULE.rules.REPEAT_0_ON_DAYS',
                {
                  startTime: formatForDatePipe(rule.startTime, 'shortTime'),
                  endTime: formatForDatePipe(rule.endTime, 'shortTime'),
                  days: getDaysText(days),
                },
              );
            }
          }
          break;

        case 1:
          if (days.length === 7) {
            console.log('RULE: repeat 1', rule.frequency);
            ruleText = translate.instant(
              'PLAYLIST.PLAYLIST_SCHEDULE.rules.REPEAT_1',
              {
                startTime: formatForDatePipe(rule.startTime, 'shortTime'),
                endTime: formatForDatePipe(rule.endTime, 'shortTime'),
                frequency:
                  rule.frequency === Frequency.Minutely
                    ? frequencyMinutely
                    : frequencyHourly,
                interval: rule.interval,
              },
            );
          } else {
            console.log('RULE: repeat 1 on days', rule.frequency);
            ruleText = translate.instant(
              'PLAYLIST.PLAYLIST_SCHEDULE.rules.REPEAT_1_ON_DAYS',
              {
                startTime: formatForDatePipe(rule.startTime, 'shortTime'),
                endTime: formatForDatePipe(rule.endTime, 'shortTime'),
                frequency:
                  rule.frequency === Frequency.Minutely
                    ? frequencyMinutely
                    : frequencyHourly,
                interval: rule.interval,
                days: getDaysText(days),
              },
            );
          }
          break;

        default:
          ruleText;
      }
    }

    return ruleText;
  });

  // // Add general schedule context if dates exist
  // if (startDateTime || endDateTime) {
  //   const scheduleContext = getGeneralScheduleText(
  //     startDateTime,
  //     endDateTime,
  //     startFullDay,
  //     endFullDay,
  //     translate,
  //     dateTimePipe,
  //   );
  //   return [scheduleContext, ...scheduleRules];
  // }

  return scheduleRules || [];
};
