import {
  Component,
  OnInit,
  Input,
  inject,
  computed,
  effect,
  signal,
  output,
  input,
} from '@angular/core';
import {
  CurrentUserService,
  EncryptionService,
  SlidePanelService,
} from '@desquare/services';
import { PlaylistStatus } from '@designage/gql';
import { ActivatedRoute } from '@angular/router';
import { TranslatePipe } from '@ngx-translate/core';
import { PlaylistFormNewComponent } from '@designage/app/playlist-editor/playlist-form-new/playlist-form-new.component';
import { PlaylistStore } from '@desquare/stores';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

enum Tabs {
  SETTINGS,
  DETAILS,
}

@Component({
  standalone: true,
  imports: [TranslatePipe, PlaylistFormNewComponent],
  selector: 'designage-playlist-manage',
  template: `
    @if (loading()) {
      <div class="progress progress-bar-custom">
        <div
          class="progress-bar progress-bar-striped progress-bar-animated progress-bar-custom"
          role="progressbar"
          aria-valuenow="100"
          aria-valuemin="0"
          aria-valuemax="100"
        >
          {{ loaderMessage() | translate }}
        </div>
      </div>
    }
    <div
      [class.disabled]="loading()"
      class="pt-3 overflow-auto container-fluid content-container-card manage-channel-container"
    >
      <!-- @if (createPlaylist()) {
        <designage-playlist-form-new
          [invokedFrom]="invokedFrom()"
          (playlistPublished)="playlistPublished.emit()"
        />
      } -->
      @if (editingReady() || createPlaylist()) {
        <designage-playlist-form-new
          [invokedFrom]="invokedFrom()"
          (playlistPublished)="playlistPublished.emit()"
          [createPlaylist]="createPlaylist()"
        />
      }
    </div>
  `,
  styles: [
    `
      .disabled {
        pointer-events: none;
      }

      .progress-bar-custom {
        width: 100%;
        height: 1.5rem;
      }
    `,
  ],
})
export class PlaylistManageNewComponent implements OnInit {
  playlistStore = inject(PlaylistStore);
  route = inject(ActivatedRoute);
  slidePanelService = inject(SlidePanelService);
  encryptionService = inject(EncryptionService);
  currentUserService = inject(CurrentUserService);

  createPlaylist = input<boolean>(false);

  playlist = computed(() => this.playlistStore.playlist());
  loading = this.playlistStore.loading;
  loaderMessage = this.playlistStore.loaderMessage;

  currentTab!: Tabs;
  tabs = Tabs;
  playlistStatus = PlaylistStatus;

  _inlinePlaylistId: string = '';
  @Input() set inlinePlaylistId(value: string) {
    // this._inlinePlaylistId = value;
    if (value) {
      this.initPlaylistDecrypted(value);
    }
  }

  invokedFrom = input<string>();
  playlistPublished = output<void>();

  constructor() {
    if (!this.inlinePlaylistId) {
      this.route.params.pipe(takeUntilDestroyed()).subscribe((params) => {
        const encryptedPlaylistId = params.playlistId;
        if (encryptedPlaylistId) {
          const decryptedId =
            this.encryptionService.decrypt(encryptedPlaylistId);
          this.initPlaylistDecrypted(decryptedId);
          this.slidePanelService.setPanelComponentId(decryptedId);
        }
      });
    }
    effect(() => {
      // console.log('createPlaylist', this.createPlaylist());
    });
  }

  get showSettingsNav() {
    return this.currentUserService.canManagePlaylist;
  }

  ngOnInit() {
    this.currentTab = this.currentUserService.canManagePlaylist
      ? Tabs.SETTINGS
      : Tabs.DETAILS;
  }

  editingReady = computed(
    () => this.editingPlaylistId() === this.playlistStore.playlist.id(),
  );

  editingPlaylistId = signal<string>('');
  async initPlaylistDecrypted(decryptedId: string) {
    this.editingPlaylistId.set(decryptedId);
    await this.playlistStore.getPlaylistFromApi(decryptedId);
  }

  canExit() {
    return true;
  }
}
